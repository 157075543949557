import styled from 'styled-components'
import { fonts, colors, breakPoints } from '@constants/styles'

export const AfterComponentWrapper = styled.span`
  display: flex;
  flex-direction: column;
`

export const AfterComponentLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
`

export const DownpaymentParagraph = styled.p`
  font-size: 14px;
`

export const CheckboxWrapper = styled.section`
  &&& {
    align-items: center;
    display: flex;
    margin-bottom: 2.5%;
    > label {
      margin-left: 5px;
      font-size: ${fonts.txtXsmall};

      > span > .link {
        color: ${colors.primary};
        display: inline;
        font-size: ${fonts.txtSmall};

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: ${breakPoints['large-max']}) {
          font-size: ${fonts.txtXsmall};
        }
      }
    }
  }
`
export const CheckboxAfterComponent = styled.span``

export const ErrorRed = styled.p`
  color: ${colors.red};
`
export const LinkButton = styled.button`
  border: none;
  color: ${colors.primary};
  width: 140px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`
