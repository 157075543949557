import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { breakPoints, colors, fonts } from '@constants/styles'
import { Modal, Box, Typography, Dialog, styled } from '@mui/material'

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2x 5px rgba(0, 0, 0, 0.2);
  width: 50%;
  height: auto;
  padding: 20px;
`
const BtnContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 20px 0 10px 0;
`

const StyledButton = styled('button')`
  background-color: ${colors.primary};
  color: ${colors.primaryInvert};
  border-radius: 0;
  padding: 12px;
  text-align: center;
  box-sizing: border-box;
  font-size: ${fonts.txtMedium};
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  @media only screen and (min-width: ${breakPoints['medium-min']}) {
    right: 1.5%;
  }
  @media only screen and (max-width: ${breakPoints.medium}) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    right: 0;
  }
  &:focus {
    outline: solid 3px -webkit-focus-ring-color;
  }
  &:hover {
    background-color: ${colors.lightBlue};
  }
`

const ShippingErrorModal = props => {
  const { showModal, onCloseModal } = props
  return (
    <Modal open={showModal} onClose={onCloseModal}>
      <StyledBox>
        <Typography id="modal-modal-description" variant="body1">
          Unable to deliver to this address. Please update Shipping Address.
        </Typography>
        <BtnContainer className="btn-container">
          <StyledButton className="continue-btn" onClick={onCloseModal} type="button">
            <>Ok</>
          </StyledButton>
        </BtnContainer>
      </StyledBox>
    </Modal>
  )
}

export default ShippingErrorModal

ShippingErrorModal.propTypes = {
  showModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
