import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setDeliveryCalendar } from '@helpers/checkout/delivery-section'
import { bool, object } from 'prop-types'
import DeliverySection from './delivery-section'

const DeliverySectionWrapper = ({ hasDeliveryData, order }) => {
  const lineItems = JSON.stringify(order?.lineItems ?? [])
  const deliveryCalendar = JSON.stringify(order?.deliveryCalendar ?? [])
  const shippingAddress = JSON.stringify(order?.shippingAddress ?? {})

  useEffect(() => {
    if (hasDeliveryData) {
      setDeliveryCalendar(order.deliveryCalendar, order.pickupCalendar, order.expressCalendar ?? [])
    }
  }, [hasDeliveryData, order, lineItems, deliveryCalendar, shippingAddress])

  return <DeliverySection order={order} />
}

DeliverySectionWrapper.propTypes = {
  hasDeliveryData: bool,
  order: object,
}

const mapStateToProps = ({ checkout: { order } }) => ({
  hasDeliveryData: !!(order && order?.totalDeliveryCharge >= 0 && order?.deliveryCalendar?.length),
  order,
})

export default connect(mapStateToProps)(DeliverySectionWrapper)
