import React from 'react'
import { arrayOf, string, shape } from 'prop-types'
import { removeCamelCase } from '@helpers/string-helper'
import '@comp-sass/checkout/checkout-parts/checkout-error-message.sass'

const ErrorMessage = ({ invalidFields = [], customMessage = {} }) => {
  const { message, id } = customMessage
  const showMessage = message !== 'Invalid button click'
  return (
    <>
      {message && showMessage && (
        <p className="invalid-message" data-testid="invalid-message" id={id}>
          *{message}
        </p>
      )}
      {!message && invalidFields.length > 0 && (
        <p className="invalid-message small-12" data-testid="invalid-message">
          *Invalid{' '}
          {invalidFields.map((field, index) => {
            const newField = removeCamelCase(field)
            if (invalidFields.length === 1) {
              return newField
            }
            if (invalidFields.length === 2) {
              return index !== invalidFields.length - 1 ? `${newField} ` : `and ${newField}`
            }
            return index !== invalidFields.length - 1 ? `${newField}, ` : `and ${newField}`
          })}
        </p>
      )}
    </>
  )
}

ErrorMessage.propTypes = {
  invalidFields: arrayOf(string),
  customMessage: shape({
    message: string,
    id: string,
  }),
}

export default ErrorMessage
