import React from 'react'
import { array, func, string } from 'prop-types'
import { Divider, Stack, styled, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { parseJwt } from '@helpers/checkout/payment-section/credit-card'
import PaymentSvg from '@components/shared/svgs/paymentSvgComp'
import { PAYMENT_CC_LABELS } from '../payment-section'
import PriceAtom from '../../../../../@rtg2022/components/atoms/ProductPrice/PriceAtom'

const PaymentMessageContainer = styled(Stack)(({ theme }) => ({
  color: theme.palette.success.main,
}))

const RemoveButton = styled('button')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  lineHeight: '18.5px',
  textDecoration: 'underline',
  '&:hover': {
    opacity: 0.75,
    cursor: 'pointer',
  },
}))

const LogoContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '35px',
}))

const MultiTenderPaymentsApplied = ({ payments, removePayment, testIdPrefix }) => {
  if (!payments || payments.length === 0) return null

  return (
    <Stack justifyContent="space-evenly" spacing={1} sx={{ paddingBottom: '16px' }}>
      <PaymentMessageContainer
        direction="row"
        alignItems="center"
        spacing={0.5}
        data-testid={`${testIdPrefix}success-message`}
      >
        <CheckCircleOutlineIcon />
        <Typography component="span" sx={{ fontSize: { xs: '14px', sm: '18px' } }}>
          Payments Applied Successfully
        </Typography>
      </PaymentMessageContainer>

      <Stack spacing={1} pl={1}>
        {payments.map(({ authAmount, card, token }, i) => {
          const { maskedCardNum } = token ? parseJwt(token) : {}
          const { appliedIndex, cardName } = card
          const paymentKey = `cc_payment_${i}`
          return (
            <Stack key={paymentKey} direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Stack direction="row">
                  <LogoContainer>
                    <PaymentSvg
                      uniqueNameForId="multitender-payments"
                      cards={[cardName]}
                      cordY="0"
                      vpHeight="50"
                      width="30px"
                      height="18px"
                    />
                  </LogoContainer>
                  <Typography>{PAYMENT_CC_LABELS[cardName]}</Typography>
                </Stack>
                <Typography pr={1.5}>{maskedCardNum}</Typography>
                <RemoveButton
                  data-testid={`${testIdPrefix}remove-card-button`}
                  onClick={() => removePayment(appliedIndex)}
                >
                  Remove
                </RemoveButton>
              </Stack>

              <div data-testid={`${testIdPrefix}deducted-amount`}>
                -&nbsp;
                <PriceAtom price={authAmount} />
              </div>
            </Stack>
          )
        })}
      </Stack>
      <Divider />
    </Stack>
  )
}

MultiTenderPaymentsApplied.propTypes = {
  payments: array,
  removePayment: func,
  testIdPrefix: string,
}

export default MultiTenderPaymentsApplied
