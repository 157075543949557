import React from 'react'
import { bool, func, object } from 'prop-types'
import { Dialog } from '@material-ui/core'
import { removeKlarnaSession } from '@helpers/checkout/payment-section/payment-section'
import KlarnaPayment from './klarna-payment'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeKlarna = ({ closeModals, hasBottomBorder = false, isOpen, openModal, order }) => {
  const onClose = () => {
    closeModals()
    removeKlarnaSession()
  }

  const { amountDue, orderId, paymentInfo, total } = order
  const hasKlarnaApplied = paymentInfo?.some(({ paymentType }) => paymentType === 'KLN')

  return (
    <div>
      <PaymentTypeButton
        hasBottomBorder={hasBottomBorder}
        isApplied={hasKlarnaApplied}
        ariaId="payment-option-synchrony"
        gtmLabel="Klarna"
        handleClick={() => openModal('klarna')}
        label="4 Interest-Free Payments"
        startIcon={
          <img
            src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
            width="100%"
            alt="Klarna payment option"
            style={{ backgroundColor: '#ffb3c7', borderRadius: '4px', height: '63px' }}
          />
        }
      />
      <Dialog
        onClose={onClose}
        aria-labelledby="payment-option-synchrony"
        open={isOpen}
        tabIndex="0"
        id="payment-modal-synchrony"
        className="rooms-to-go-credit-container"
        PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
      >
        <KlarnaPayment orderId={orderId} total={amountDue || total} onClose={onClose} />
      </Dialog>
    </div>
  )
}

PaymentTypeKlarna.propTypes = {
  closeModals: func,
  hasBottomBorder: bool,
  isOpen: bool,
  openModal: func,
  order: object,
}

export default PaymentTypeKlarna
