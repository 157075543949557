import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { styled } from '@mui/material'
import Layout from '../components/layout'
import CheckoutMain from '../components/checkout'

const StyledLayout = styled(Layout)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.white}!important`,
  '& .content-wrapper': {
    backgroundColor: `${theme.palette.common.white}!important`,
  },
}))

const Checkout = ({ props }) => (
  <StyledLayout {...props} checkout>
    <Helmet title="Checkout - Rooms To Go" meta={[{ name: 'robots', content: 'noindex, nofollow' }]}>
      <script src="https://x.klarnacdn.net/kp/lib/v1/api.js" async />
    </Helmet>
    <CheckoutMain />
  </StyledLayout>
)

Checkout.propTypes = {
  props: PropTypes.any,
}

export default Checkout
