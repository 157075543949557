import styled from 'styled-components'
import { colors, fonts } from '@constants/styles'

export const ReviewSectionWrapper = styled.form({
  textAlign: 'left',
  width: '97.5%',
  marginBottom: '1em',
  marginLeft: '1em',
  fontSize: `${fonts.txtSmall}`,
  '.invalid ': {
    color: `${colors.red}`,
    marginTop: '1rem',
  },
  '.checkbox-container': {
    display: 'inline-block',
    margin: '1.5em 3em 1.5em 0em',
    p: {
      display: 'inline',
    },
    input: {
      float: 'left',
      width: '0.8125rem',
      minWidth: '0.8125rem',
      height: '0.8125rem',
      marginTop: '0.5em',
    },
    '.text-container': {
      display: 'block',
      width: '90%',
      marginLeft: '1.75em',
      fontSize: 'initial',
      '.terms-header': {
        fontWeight: 'bold',
        display: 'block',
      },
      '.accept-link': {
        color: `${colors.primary}`,
        marginLeft: '0.25em',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '.delivery-dates': {
        color: `${colors.red}`,
      },
    },
  },
})

export const ModalContentWrapper = styled.div`
  padding: 2.5em;
  font-size: 1.2em;
`
