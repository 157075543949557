import React from 'react'
import { shape, string } from 'prop-types'
import { Stack } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'

const iconWrapperStyle = {
  marginTop: 2,
  width: 32,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 4,
  backgroundColor: 'rgba(0, 83, 160, 0.04)',
  borderRadius: '50%',
}

const formattedPhoneNumber = num => {
  if (num?.length !== 10) return num

  return `${num.slice(0, 3)}-${num.slice(3, 6)}-${num.slice(6)}`
}

const ShippingCondensed = ({ contact, shippingAddress }) => (
  <Stack
    direction={{ xs: 'column', sm: 'row' }}
    spacing={{ xs: 2, sm: 12 }}
    sx={{ textAlign: 'left' }}
    mt={{ xs: 2.5, sm: 2 }}
    pb="4px"
  >
    <Stack direction="row" gap={1}>
      <div style={iconWrapperStyle}>
        <PersonIcon htmlColor="rgba(0, 83, 160, 0.3)" />
      </div>
      <div style={{ textTransform: 'capitalize' }}>
        {`${contact.firstName} ${contact.lastName}`}
        <br />
        {shippingAddress.line1}
        <br />
        {shippingAddress.line2}
      </div>
    </Stack>

    <div>
      <Stack direction="row" gap={1} alignItems="center">
        <div style={iconWrapperStyle}>
          <EmailIcon htmlColor="rgba(0, 83, 160, 0.3)" />
        </div>
        {contact.email}
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <div style={iconWrapperStyle}>
          <PhoneIcon htmlColor="rgba(0, 83, 160, 0.3)" />
        </div>
        {formattedPhoneNumber(contact.phone)}
      </Stack>

      {contact.altPhone && (
        <Stack direction="row" gap={1} alignItems="center">
          <div style={iconWrapperStyle}>
            <PhoneIcon htmlColor="rgba(0, 83, 160, 0.3)" />
          </div>
          {formattedPhoneNumber(contact.altPhone)}
        </Stack>
      )}
    </div>
  </Stack>
)

ShippingCondensed.propTypes = {
  contact: shape({
    altPhone: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
  }),
  shippingAddress: shape({
    line1: string,
    line2: string,
  }),
}

export default ShippingCondensed
