import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { breakPoints, colors, fonts } from '@constants/styles'
import { getAppliedPayments } from '@helpers/checkout/payment-section/payment-section'
import AppliedPaymentCard from './applied-payment-card'
import FinanceMessage from './finance-message'

const StyledPaymentCondensed = styled('div')`
  text-align: left;
  margin: 1.25rem 0 1.25rem 1em;
  @media only screen and (max-width: ${breakPoints.small}) {
    margin: 1.25rem 0;
  }
  h3 {
    font-weight: 600;
    text-align: left;
  }
  .billing-condensed {
    margin-bottom: 1rem;
    .apple-pay-button {
      width: auto;
      height: 1.75em;
    }
  }
  .giftcard {
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 6rem;
      li {
        padding-right: 0.25rem;
        font-size: ${fonts.txtSmall};
        @media only screen and (min-width: ${breakPoints['large-min']}) {
          font-size: ${fonts.txtMedium};
        }
      }
    }
    button {
      font-weight: 600;
      color: ${colors.primary};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`
const PaymentCondensedTitle = styled(Typography)({
  margin: '15px 0',
})

const PaymentCondensed = ({ order }) => {
  const { paymentInfo } = order
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const appliedPayments = getAppliedPayments(paymentInfo)

  return (
    <>
      <StyledPaymentCondensed className="payment-condensed grid-x" data-testid="payment-condensed">
        {!isMobile && <PaymentCondensedTitle>Payments Toward Total</PaymentCondensedTitle>}
        <Grid container spacing={1}>
          {appliedPayments.map((payment, i) => {
            const divKey = `pymnt_${i}_${payment.paymentType}`
            return (
              <Grid item key={divKey} sx={{ width: isMobile ? '100%' : 'auto' }}>
                <AppliedPaymentCard isMobile={isMobile} payment={payment} order={order} />
              </Grid>
            )
          })}
        </Grid>
        <FinanceMessage paymentInfo={paymentInfo} />
      </StyledPaymentCondensed>
    </>
  )
}

PaymentCondensed.propTypes = {
  order: PropTypes.object,
}

export default PaymentCondensed
