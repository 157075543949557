import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { objectOf, any, string, arrayOf, func } from 'prop-types'
import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { getFromBrowserStorage } from '@helpers/storage'
import { updateAddress } from '@services/checkout'
import { stateSelectList } from '@helpers/geo-location'
import {
  getAddressSpecificBody,
  onClickLookupAddress,
  onStateChange,
  setContactInfo,
  setShippingAddressInfo,
} from '@helpers/checkout/shipping-section'
import { setOrderField, setOrderInfo } from '@helpers/checkout/global'
import { generateErrorMessage } from '@helpers/errors'
import AddressAutocomplete from './address-autocomplete'
import { AddressTip, PhoneDisclaimer } from '../tips'
import AddressSuggestionModal from '../address-suggestion-modal'

const ShippingSection = ({ getTestID, invalidFields, order, updateInvalidFields }) => {
  const [showLookupAddress, setShowLookupAddress] = useState(!!order?.shippingAddress?.showAddressLookup)
  const [showAltPhoneInput, setShowAltPhoneInput] = useState(!!order?.contact?.altPhone)
  const [forceLookupOpen, setForceLookupOpen] = useState(false)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isIpad = useMediaQuery(theme => theme.breakpoints.up('sm'))
  const hasInvalidFields = useMemo(() => invalidFields?.length > 0, [invalidFields])

  const firstNameInputRef = useRef(null)
  const lastNameInputRef = useRef(null)
  const addressLookupRef = useRef(null)
  const address1InputRef = useRef(null)
  const address2InputRef = useRef(null)
  const cityInputRef = useRef(null)
  const stateInputRef = useRef(null)
  const zipInputRef = useRef(null)
  const phoneInputRef = useRef(null)
  const altPhoneInputRef = useRef(null)
  const emailInputRef = useRef(null)
  const requiredFieldsRef = useRef(null)

  const refs = {
    firstName: firstNameInputRef,
    lastName: lastNameInputRef,
    addressLookup: addressLookupRef,
    address1: address1InputRef,
    address2: address2InputRef,
    city: cityInputRef,
    state: stateInputRef,
    zip: zipInputRef,
    phone: phoneInputRef,
    altPhone: altPhoneInputRef,
    email: emailInputRef,
    required: requiredFieldsRef,
  }

  const { contact, shippingAddress, storeInfo } = order
  const {
    address1,
    address2,
    addressLookup,
    addressLookupSuccess,
    globalAddressId,
    showAddressLookup,
    city,
    state,
    zip,
  } = shippingAddress
  const isStoreCart = !!storeInfo && !!storeInfo?.storeCartId

  const openLookup = () => {
    setForceLookupOpen(true)
  }
  const closeLookup = () => {
    setForceLookupOpen(false)
  }

  useEffect(() => {
    let newShippingAddress = { ...shippingAddress }
    let changed = false

    // If it's store-cart then remove auto-completed address because it'll receive address
    // from store-cart. For more information please see the FD-1669
    if (isStoreCart) {
      changed = !!addressLookup || addressLookupSuccess || showAddressLookup
      newShippingAddress = {
        ...newShippingAddress,
        addressLookup: '',
        addressLookupSuccess: false,
        showAddressLookup: false,
      }
    } else {
      const stepOneManualEntry = getFromBrowserStorage('session', 'StepOneManualEntry')

      if (stepOneManualEntry.toString() === 'true') {
        changed = showAddressLookup
        newShippingAddress.showAddressLookup = false
      } else if (addressLookupSuccess && addressLookup) {
        // Update to accurate shipping info from addressLookup if unavailable product error occurred
        // after changing address and user was booted back to cart
        changed = true
        const addressLookupSpacedParts = addressLookup.split(' ')
        newShippingAddress = {
          ...newShippingAddress,
          address1: addressLookup.split(',')[0],
          city:
            addressLookup
              ?.split(',')?.[1]
              ?.split(' ')
              ?.slice(1, -2)
              ?.toString()
              ?.replaceAll(',', ' ') ?? '',
          showAddressLookup: false,
          state: addressLookupSpacedParts[addressLookupSpacedParts.length - 2],
          zip: addressLookup.slice(-5),
        }
      } else if (addressLookupSuccess && !addressLookup) {
        changed = true
        newShippingAddress.addressLookupSuccess = false
      }
    }

    if (changed) {
      setOrderField('shippingAddress', newShippingAddress)
    }

    // FD-2316 - condition to check if we should reset or not the state of the order based on ?checkout_token param in URL
    const index = window.location.href.indexOf('?checkout_token')
    if (addressLookupSuccess && addressLookup && index === 0) {
      updateAddress(getAddressSpecificBody(order))
    }

    // return () => updateInvalidFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // if there are invalid fields, move focus to the first invalid input
    if (hasInvalidFields) {
      scrollToInvalid()
    } else if (!contact.firstName && firstNameInputRef.current) {
      firstNameInputRef.current.focus()
    } else if (!contact.lastName && lastNameInputRef.current) {
      lastNameInputRef.current.focus()
    } else if (!showLookupAddress && address1InputRef.current) {
      address1InputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollToInvalid = useCallback(() => {
    const invalidRefName = Object.keys(refs).find(fld => invalidFields.includes(fld))
    if (invalidRefName) {
      // refs[invalidRefName]?.current?.scrollIntoView({ behavior: 'smooth' })
      refs[invalidRefName]?.current?.focus()
    }
  }, [invalidFields, refs])

  const showSuggestionModal = invalidFields?.includes('unable to verify') ?? false
  // const scrollMargin = '150px'

  // const scrollToCheckoutInput = field => {
  //   if (refs[field]?.current) {
  //     refs[field].current.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }

  // const handleLookupAddressBtnClick = e => {
  //   if (e?.type !== 'keydown' || e?.code === 'Space' || e?.key === 'Enter' || e?.type === 'click') {
  //     e.preventDefault()
  //     if (!addressLookupSuccess) onClickLookupAddress({ addressLookup: `${address1} ${address2}`.trim() })
  //     setShowLookupAddress(true)

  //     // move focus to the addressLookup field
  //     setTimeout(() => {
  //       if (refs.addressLookup?.current) {
  //         refs.addressLookup.current?.focus()
  //       } else {
  //         document.getElementById('address-autocomplete')?.focus()
  //       }
  //     }, 1000)
  //   }
  // }

  const handleManualAddressBtnClick = e => {
    if (e.type !== 'keydown' || e.code === 'Space' || e.key === 'Enter') {
      e.preventDefault()
      if (!addressLookupSuccess) onClickLookupAddress({ address1: addressLookup ?? '', showAddressLookup: false })
      setShowLookupAddress(false)

      // move focus to the address1 field
      if (refs.address1?.current) {
        refs.address1.current?.focus()
      } else {
        document.getElementById('address1')?.focus()
      }
    }
  }

  const resetAddressFields = () => {
    if (!address1 && !address2 && !city && !zip && !state) return null
    const addressFieldsToClear = ['address1', 'address2', 'city', 'state', 'zip']

    if (invalidFields.some(fld => addressFieldsToClear.includes(fld))) {
      updateInvalidFields(invalidFields.filter(f => !addressFieldsToClear.includes(f) && !f.endsWith('po-box')))
    }

    onClickLookupAddress()
    if (address1InputRef.current) address1InputRef.current.focus()
    return null
  }

  const getTextFieldProps = (field, label) => {
    const isError = invalidFields?.includes(field) ?? false
    const poBoxWarning = invalidFields?.includes(`${field}-po-box`) ? `* ${generateErrorMessage('po box').message}` : ''
    const isRequired = !['address2', 'altPhone'].includes(field)
    const isAddressField = ['address1', 'address2', 'city', 'zip'].includes(field)
    const isNarrowWidthField = ['altPhone', 'phone', 'zip'].includes(field) || (field === 'city' && !isMobile)
    const sxWideField = isMobile && !isIpad ? null : { width: 450 }
    const sxNarrowField = { width: 250 }
    if (field === 'city') sxNarrowField.width = 325
    if (field === 'zip' && isMobile) sxNarrowField.width = 150

    const handleChange = e => {
      if (isError) updateInvalidFields(invalidFields.filter(f => f !== field && f !== `${field}-po-box`))

      if (isAddressField) {
        setShippingAddressInfo({
          [field]: e.target.value,
          addressLookup: '',
          addressLookupSuccess: false,
          globalAddressId: '',
        })
      } else {
        setContactInfo(e.target.value, field)
      }
    }

    return {
      fullWidth: true,
      error: isError,
      FormHelperTextProps: { style: { fontSize: 12, fontWeight: 400, textWrap: isMobile ? 'wrap' : 'nowrap' } },
      helperText: isError ? poBoxWarning || `* Please enter a valid ${label}` : null,
      id: field,
      inputProps: {
        'data-testid': getTestID(`${field}-input`),
        style: !['email', 'phone', 'altPhone', 'zip'].includes(field) ? { textTransform: 'capitalize' } : null, // TODO - probably should handle this differently to avoid changes as user types
        'aria-labelledby': `Step1Header ${field}-label`,
        'aria-required': isRequired,
      },
      inputRef: refs[field],
      label: (
        <>
          {label} <span style={{ fontSize: 18, color: '#E11F21' }}>{isRequired ? '*' : ''}</span>
        </>
      ),
      onChange: handleChange,
      sx: isNarrowWidthField ? sxNarrowField : sxWideField,
    }
  }
  let addressLookupValue = address1 ? { id: 0, label: `${address1} ${address2}`.trim() } : null
  if (addressLookupSuccess) addressLookupValue = { id: 0, label: addressLookup || address1, globalAddressId }

  return (
    <>
      {showSuggestionModal && (
        <AddressSuggestionModal
          address={shippingAddress}
          modalOpen={showSuggestionModal}
          suggestion={order?.suggestedAddress?.suggestedAddress}
          formattedSuggestion={order?.suggestedAddress?.formattedAddress}
        />
      )}
      <Stack alignItems="flex-end" justifyContent="flex-end" mb={2}>
        <Typography ref={requiredFieldsRef} variant="body1" sx={{ fontSize: 14, color: '#E11F21' }}>
          *Required
        </Typography>
      </Stack>
      <Stack alignItems="flex-start" component="form" gap="20px" pl={{ xs: 0, md: 3 }}>
        <TextField
          {...getTextFieldProps('firstName', 'First Name')}
          value={contact.firstName}
          autoComplete="given-name"
        />

        <TextField
          {...getTextFieldProps('lastName', 'Last Name')}
          value={contact.lastName}
          autoComplete="family-name"
        />

        {showLookupAddress ? (
          <Stack alignItems="flex-start" sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="center" sx={{ width: isMobile ? '100%' : 450 }}>
              <AddressAutocomplete
                addressLookupValue={addressLookupValue}
                inputRef={refs.addressLookup}
                isInvalid={invalidFields.includes('addressLookup')}
                invalidFields={invalidFields}
                showManualAddress={() => setShowLookupAddress(false)}
                testId={getTestID('address-lookup-input')}
                updateInvalidFields={updateInvalidFields}
                width={isMobile ? '100%' : 450}
                forceOpen={forceLookupOpen}
                onFocus={openLookup}
                onBlur={closeLookup}
              />
              <AddressTip />
            </Stack>
            <Button
              onClick={handleManualAddressBtnClick}
              onKeyDown={handleManualAddressBtnClick}
              size="small"
              sx={{ textDecoration: 'underline', textTransform: 'none', fontSize: 14 }}
            >
              Enter Address Manually
            </Button>
          </Stack>
        ) : (
          <>
            {/* 
            // TODO TEMPORARILY DISABLING Lookup Address FUNCTIONALITY - If decide to permanently remove, then need to clean up everything associated with showLookupAddress and handleLookupAddressBtnClick
            <Stack alignItems="flex-start" sx={{ width: '100%' }}>
              <Stack direction="row" alignItems="center" sx={{ width: isMobile ? '100%' : 450 }}>
                <TextField {...getTextFieldProps('address1', 'Street Address')} value={address1} />
                <AddressTip />
              </Stack>
              <Button
                onClick={handleLookupAddressBtnClick}
                onKeyDown={handleLookupAddressBtnClick}
                size="small"
                sx={{ textDecoration: 'underline', textTransform: 'none', fontSize: 14 }}
              >
                Lookup Address
              </Button>
            </Stack> */}
            <Stack sx={{ alignItems: 'flex-start', width: '100%' }}>
              <TextField {...getTextFieldProps('address1', 'Street Address')} value={address1} />
              <Button
                onClick={resetAddressFields}
                sx={{
                  fontSize: '14px',
                  lineHeight: 1.15,
                  textDecoration: 'underline',
                  textTransform: 'capitalize',
                  marginTop: '5px',
                  padding: 0,
                }}
                data-testid={getTestID('clear-address-button')}
              >
                Clear Address
              </Button>
            </Stack>
            <TextField {...getTextFieldProps('address2', 'Address Line 2')} value={address2} />
            <TextField {...getTextFieldProps('city', 'City')} value={city} />

            <Stack direction={{ xs: 'row', md: 'column' }} alignItems="flex-start" sx={{ width: '100%' }} spacing={2}>
              <TextField
                error={invalidFields?.includes('state') ?? false}
                fullWidth
                id="state"
                value={state?.toUpperCase() ?? ''}
                inputRef={refs.state}
                label={
                  <>
                    State <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
                  </>
                }
                onChange={e => {
                  if (invalidFields?.includes('state')) updateInvalidFields(invalidFields.filter(f => f !== 'state'))
                  onStateChange(e.target.value, state)
                }}
                select
                SelectProps={{
                  native: true,
                  inputProps: {
                    'data-testid': getTestID('state-input'),
                    style: { paddingBottom: '15px', paddingTop: '15px' },
                    'aria-label': 'Shipping State dropdown',
                    'aria-required': 'true',
                  },
                }}
                sx={{ textAlign: 'left', width: 104 }}
              >
                <option aria-label="None" value="" /> {/* Need this for Safari browser */}
                {stateSelectList.map(st => (
                  <option key={`state_dropdown_item_${st}`} value={st}>
                    {st}
                  </option>
                ))}
              </TextField>

              <TextField {...getTextFieldProps('zip', 'Zip Code')} value={zip} />
            </Stack>
          </>
        )}

        <TextField {...getTextFieldProps('email', 'Email')} value={contact.email} autoComplete="email" />

        <Stack alignItems="flex-start">
          <Stack direction="row" alignItems="center">
            <TextField
              {...getTextFieldProps('phone', 'Phone Number')}
              value={contact.phone}
              autoComplete="tel"
              type="tel"
            />
            <PhoneDisclaimer />
          </Stack>
          {!showAltPhoneInput && (
            <Button
              onClick={() => setShowAltPhoneInput(true)}
              size="small"
              sx={{ textDecoration: 'underline', textTransform: 'none', fontSize: 14 }}
            >
              <AddIcon fontSize="small" /> Add Alternative Phone
            </Button>
          )}
        </Stack>

        {showAltPhoneInput && (
          <TextField
            {...getTextFieldProps('altPhone', 'Alternative Phone Number')}
            value={contact.altPhone}
            autoComplete="tel"
            type="tel"
          />
        )}

        <FormControlLabel
          sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}
          control={
            <Checkbox
              checked={!!order?.emailCampaign}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={e => setOrderInfo(e.target.checked, 'emailCampaign')}
              data-testid={getTestID('emailcampaign-checkbox')}
              sx={{ pt: { xs: '4px', sm: '9px' } }}
            />
          }
          label={
            <Typography sx={{ textAlign: 'left' }}>
              I want to receive Rooms To Go email promotions and news letters.
            </Typography>
          }
        />
      </Stack>
    </>
  )
}

ShippingSection.propTypes = {
  getTestID: func,
  invalidFields: arrayOf(string),
  order: objectOf(any),
  updateInvalidFields: func,
}

export default ShippingSection
