import React from 'react'
import { bool, object, string } from 'prop-types'
import classNames from 'classnames'
import Input from '@shared/input'
import '@assets/css/components/checkout/checkout-parts/checkout-input.sass'

const CheckoutInput = ({ additional, hasVerbiage, placeholder, selectProps, ...props }) => {
  const { field, label, required, type } = props
  const labelText = required ? `${label}*` : label
  const holder = placeholder || label
  const textType = ['email', 'select', 'tel', 'text'].includes(type)

  if (type === 'select') {
    if (!selectProps) return null
    const { className, isInvalid, noneOption, onChange, onFocus, options, parentRef, testID, value } = selectProps
    const hasNoneOption = !!noneOption

    return (
      <label className={classNames('label', field)} htmlFor={field}>
        {labelText}
        <select
          name={field}
          id={field}
          className={classNames(className, { invalid: isInvalid })}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          data-testid={testID}
          ref={parentRef}
        >
          {hasNoneOption && <option value={noneOption.value}>{noneOption.label}</option>}
          {options.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </label>
    )
  }

  return (
    <>
      {textType && label && (
        <label className={classNames('label', field)} htmlFor={field}>
          {labelText}
          {additional ? <span className="hide508">{additional}</span> : null}
          {hasVerbiage ? <span>&#8224;&#8224;</span> : null}
        </label>
      )}
      <Input
        {...props}
        textType={textType}
        radioType={type === 'radio'}
        labelText={labelText}
        placeholder={holder}
        noLabel={textType}
      />
    </>
  )
}

CheckoutInput.propTypes = {
  additional: string,
  field: string,
  hasVerbiage: bool,
  label: string,
  placeholder: string,
  required: bool,
  selectProps: object,
  type: string,
}

export default CheckoutInput
