import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, styled } from '@mui/material'
import { setOrderInfo } from '@helpers/checkout/global'
import { applePaySupported } from '@helpers/checkout/payment-section/apple-pay'
import PaypalButton from './payment-section/paypal'
import ApplePayButton from './payment-section/apple-pay'

const PaymentContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  width: '50%',
  paddingTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}))

const ExpressCheckout = ({ order, total, showPayPal }) => {
  const [payPalLoaded, setPayPalLoaded] = useState(true)
  const showApplePay = Boolean(applePaySupported())

  if (!payPalLoaded && !showApplePay) return null

  return (
    <>
      <Typography variant="h2" textTransform="capitalize" fontWeight={400}>
        Express Checkout
      </Typography>
      <Typography variant="body1">
        Save time at checkout using the following express payment options or continue for additional payment methods.
      </Typography>
      <PaymentContainer>
        <PaypalButton
          handleScriptFail={() => setPayPalLoaded(false)}
          isExpress
          total={total}
          order={order}
          show={showPayPal}
          setOrderInfo={() => setOrderInfo('PayPal', 'selectedPaymentType')}
        />
        <ApplePayButton isExpress type="plain" />
      </PaymentContainer>
    </>
  )
}

ExpressCheckout.propTypes = {
  order: PropTypes.object,
  total: PropTypes.number,
  showPayPal: PropTypes.bool,
}

export default ExpressCheckout
