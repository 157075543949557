import React from 'react'
import { any, bool, func, object, string } from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import loaderLight from '@assets/images/loader-light.svg'
import { useTest } from '@hooks/useTest'
import { DialogDivider, LoadingSpinner } from './payment-section/styles'

const defaultPaperStyles = {
  margin: '12px',
  maxWidth: '500px',
  width: 'calc(100% - 24px)',
}

const CheckoutDialog = ({
  actionButtonText,
  closeButtonText = 'Close',
  children,
  contentSyles = { padding: '20px 20px 0' },
  dataTestIdPrefix,
  dialogClass,
  dialogId,
  handleClose,
  handleContinue,
  isOpen,
  loading,
  paperStyles = {},
  showActionsDivider = true,
  title,
}) => {
  const { generateTestId } = useTest()
  const actionBtnTestId = generateTestId(dataTestIdPrefix, actionButtonText)
  const closeBtnTestId = generateTestId(dataTestIdPrefix, closeButtonText)

  const dialogPaperProps = { style: { ...defaultPaperStyles, ...paperStyles } }
  const hasActions = Boolean(handleClose || handleContinue)

  return (
    <Dialog
      aria-labelledby="checkout-dialog-title"
      className={dialogClass}
      fullWidth
      id={dialogId}
      onClose={!handleClose ? handleContinue : handleClose}
      open={isOpen}
      PaperProps={dialogPaperProps}
      tabIndex="0"
    >
      {title && (
        <DialogTitle id="checkout-dialog-title" component="div" sx={{ p: '24px 24px 12px 24px' }}>
          <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent style={contentSyles}>{children}</DialogContent>

      {hasActions && showActionsDivider && <DialogDivider />}

      {hasActions && (
        <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
          {handleClose && (
            <Button fullWidth variant="outlined" onClick={!loading ? handleClose : null} data-testid={closeBtnTestId}>
              {closeButtonText}
            </Button>
          )}

          {handleContinue && (
            <Button
              sx={{ height: '40px' }}
              disabled={loading}
              fullWidth
              variant="contained"
              onClick={!loading ? handleContinue : null}
              data-testid={actionBtnTestId}
            >
              {!loading && (actionButtonText ?? 'Continue')}
              {loading && <LoadingSpinner alt="Continue button clicked" src={loaderLight} />}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

CheckoutDialog.propTypes = {
  actionButtonText: string,
  closeButtonText: string,
  children: any,
  contentSyles: object,
  dataTestIdPrefix: string,
  dialogClass: string,
  dialogId: string,
  handleClose: func,
  handleContinue: func,
  isOpen: bool,
  loading: bool,
  paperStyles: object,
  showActionsDivider: bool,
  title: string,
}

export default CheckoutDialog
