import React, { useState } from 'react'
import { array, bool, func, object, number } from 'prop-types'
import { format, parse, add } from 'date-fns'
import { colors } from '@constants/styles'
import { Badge, styled } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import PickerLegend from './date-picker-legend'

const StyledCalendarPicker = styled(CalendarPicker)({
  '& .Mui-disabled': {
    color: '#C2BEBC !important',
  },
  '& button': {
    fontSize: '16px',
  },
  '& .MuiTypography-caption': {
    fontSize: '16px',
  },
  '& .today': {
    border: '1px solid grey !important',
    borderStyle: 'dashed!important',
  },
  '& .PrivatePickersSlideTransition-root': {
    minHeight: '240px',
  },
  '& .MuiPickersDay-root': {
    '&:focus': {
      backgroundColor: '#0053a0 !important',
      color: 'white !important',
    },
    '&:hover': {
      backgroundColor: '#b3b3b3',
      transition: 'background-color 0.2s ease-out',
    },
  },
  /* prevent express badge from showing when
  express date is at the end of previous month */
  '& .expressBadge': {
    'div ~ span': {
      display: 'none',
    },
  },
})

const StyledHr = styled(`hr`)({
  height: `2px`,
  borderTop: `none`,
  color: colors.lightGrey,
  backgroundColor: colors.lightGrey,
})

const DatePicker = ({ dates = [], isPickup, selectedDate, setSelectedDate, expressFee }) => {
  const mySelectedDate = parse(selectedDate.date, 'yyyy-MM-dd', new Date())
  const [_selectedDate, setDate] = useState(mySelectedDate)

  /* disable today as an available pick-up date */
  const tomorrow = add(new Date(), { days: 1 })

  const disabledDays = excludeDate => !dates.find(date => date?.date.includes(format(excludeDate, 'yyyy-MM-dd')))
  const renderDays = (day, selectedDays, DayComponentProps) => {
    const expressDates = dates.filter(date => date.isExpressDelivery)?.slice(0, 1)
    /* nested ternary disallowed by eslint */
    if (expressDates.find(d => d.date.includes(format(day, 'yyyy-MM-dd')))) {
      return (
        <Badge
          className="expressBadge"
          key={day.toString()}
          overlap="circular"
          badgeContent={<BoltIcon color="success" />}
        >
          <PickersDay {...DayComponentProps} />
        </Badge>
      )
    }
    if (new Date().toDateString() === day.toDateString()) {
      return <PickersDay {...DayComponentProps} className="today" />
    }
    return <PickersDay {...DayComponentProps} />
  }

  const hasExpress = dates.some(date => date.isExpressDelivery)
  const onDateChange = newDate => {
    setDate(newDate)
    setSelectedDate({
      date: format(newDate, 'yyyy-MM-dd'),
      readable: format(newDate, 'LLL dd'),
      dayOfWeek: format(newDate, 'EEE'),
      isExpress: dates.some(date => date.date.includes(format(newDate, 'yyyy-MM-dd')) && date.isExpressDelivery),
    })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledCalendarPicker
        onChange={onDateChange}
        allowKeyboardControl
        autoFocus
        date={_selectedDate}
        disableHighlightToday
        disablePast
        // initialFocusedDate={_selectedDate}
        label="Change Delivery Date"
        maxDate={new Date(dates?.[dates.length - 1]?.date)}
        minDate={tomorrow}
        renderDay={renderDays}
        shouldDisableDate={disabledDays}
        view="day"
        views={['day']}
      />
      <StyledHr />
      <PickerLegend dt={selectedDate.date} hasExpress={hasExpress} isPickup={isPickup} expressFee={expressFee} />
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  dates: array,
  isPickup: bool,
  selectedDate: object,
  setSelectedDate: func,
  expressFee: number,
}

export default DatePicker
