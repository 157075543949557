import React from 'react'
import { bool, func } from 'prop-types'
import { Dialog } from '@material-ui/core'

import ConcoraLogo from '@assets/images/co-concora.svg'
import ConcoraCredit from './concora-credit'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeConcora = ({ closeModals, isApplied, isOpen, openModal }) => (
  <div>
    <PaymentTypeButton
      ariaId="payment-option-genesis"
      gtmLabel="Concora Credit"
      handleClick={() => openModal('genesis')}
      isApplied={isApplied}
      label="Concora Credit"
      startIcon={<img src={ConcoraLogo} width="100px" style={{ margin: '0 auto' }} alt="Concora Credit" />}
    />
    <Dialog
      onClose={closeModals}
      aria-labelledby="payment-option-genesis"
      open={isOpen} // modalState.genesis
      id="payment-modal-genesis"
      tabIndex="0"
      className="rooms-to-go-credit-container"
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
    >
      <ConcoraCredit closeModal={closeModals} />
    </Dialog>
  </div>
)

PaymentTypeConcora.propTypes = {
  closeModals: func,
  isApplied: bool,
  isOpen: bool,
  openModal: func,
}

export default PaymentTypeConcora
