import React, { useState } from 'react'
import { node, object, string } from 'prop-types'
import { IconButton, Popover } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const PopoverTip = ({ children, iconAriaLabel = 'popover tip', iconSize = 'small', popoverProps = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const vpWidth = typeof window !== 'undefined' ? window.innerWidth : null
  const hasFixedPostion = vpWidth && vpWidth < 1024

  const muiPopoverProps = {
    anchorPosition: hasFixedPostion ? { top: 150, left: (vpWidth - 300) / 2 } : null,
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    anchorReference: hasFixedPostion ? 'anchorPosition' : 'anchorEl',
    transformOrigin: { horizontal: 'left', vertical: hasFixedPostion ? 'top' : 'bottom' },
    ...popoverProps,
  }

  return (
    <>
      <IconButton aria-label={iconAriaLabel} size={iconSize} onClick={handleClick}>
        <InfoOutlinedIcon fontSize={iconSize} />
      </IconButton>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} {...muiPopoverProps}>
        {children}
      </Popover>
    </>
  )
}

PopoverTip.propTypes = {
  children: node,
  iconAriaLabel: string,
  iconSize: string,
  popoverProps: object,
}

export default PopoverTip
