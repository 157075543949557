import { useState, useEffect } from 'react'

/**
 * Usage:
 *    when a boolean is needed that will be initialized with false,
 *    and when it set to be true, it'll never get falsy.
 *
 *    It'll be set to true based on input boolean's value true or false,
 *    by default it'll be set to true based on truthy value of the base input.
 *
 *    you can reverse it by passing initial value of it.
 *
 *    IMPORTANT-NOTE: CHANGE WILL BE TRIGGED WHEN BASE INPUT GET CHANGED FROM FALSE TO TRUE
 *
 * @param {boolean} baseInputBool
 * @param {boolean} initialValue
 * @returns {boolean}
 */
const useOneTimeChangeBool = (baseInputBool, initialValue = false) => {
  const [oneTimeChangeBool, setOneTimeChangeBool] = useState(initialValue)

  useEffect(() => {
    if (baseInputBool) {
      setOneTimeChangeBool(true)
    }
  }, [baseInputBool])

  return oneTimeChangeBool
}

export default useOneTimeChangeBool
