import React, { useState } from 'react'
import { string, object, func } from 'prop-types'
import styled from 'styled-components'
import { PrimaryButton } from '@shared/button-types'
import loaderLight from '@assets/images/loader-light.svg'

const StyledImage = styled.img`
  height: 1.5rem;
  width: 100%;
`
const SubmitButton = ({ buttonText, onSubmit, selectedDate }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const handleSubmit = () => {
    setLoading(true)
    onSubmit(selectedDate)
      .then(() => {
        setLoading(false)
      })
      .catch(e => {
        setError(e)
        setLoading(false)
      })
  }

  return (
    <div className="update-container cell small-12">
      {error && <p className="error">There was an error updating your date. Please try again.</p>}
      <PrimaryButton
        tabIndex="0"
        value="Change Date"
        aria-label="Change Date"
        onClick={() => handleSubmit()}
        type="button"
        style={{ textTransform: `uppercase`, width: '90%' }}
      >
        {!loading ? buttonText || 'Change Date' : <StyledImage src={loaderLight} alt="updating date" />}
      </PrimaryButton>
    </div>
  )
}

SubmitButton.propTypes = {
  buttonText: string,
  selectedDate: object.isRequired,
  onSubmit: func.isRequired,
}

export default SubmitButton
