import React from 'react'
import { bool, func, string } from 'prop-types'
import { Dialog } from '@material-ui/core'

import AcimaLogo from '@assets/images/co-acima.svg'
import AcimaModal from './acima-modal'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeAcima = ({ closeModals, hasBottomBorder = false, isOpen, openModal, orderId }) => (
  <div>
    <PaymentTypeButton
      ariaId="payment-option-acima"
      gtmLabel="acima"
      handleClick={() => openModal('acima')}
      hasBottomBorder={hasBottomBorder}
      label="No Credit Needed"
      startIcon={<img src={AcimaLogo} width="100px" style={{ margin: '0 auto' }} alt="Acima Leasing" />}
    />
    <Dialog
      onClose={closeModals}
      aria-labelledby="payment-option-acima"
      open={isOpen} // modalState.acima
      id="payment-modal-acima"
      tabIndex="0"
      className="rooms-to-go-credit-container"
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
    >
      <AcimaModal closeModal={closeModals} orderId={orderId} />
    </Dialog>
  </div>
)

PaymentTypeAcima.propTypes = {
  closeModals: func,
  hasBottomBorder: bool,
  isOpen: bool,
  openModal: func,
  orderId: string,
}

export default PaymentTypeAcima
