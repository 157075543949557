import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { productPrice } from '@helpers/product'
import PriceAtom from '../../atoms/ProductPrice/PriceAtom'
import CouponTag from '../../atoms/CouponTag/CouponTag'

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: '#333333',
}))
const ItemQuantity = styled(Item)({
  paddingTop: 0,
})
const ItemImage = styled(Item)({
  // backgroundColor: '#f6f6f6', // Cannot use gray background for images since Store Cart items have white background
  textAlign: 'center',
})
const Image = styled('img')({
  maxHeight: '56px',
})
const StyledTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.24px',
  color: '#333333',
  textAlign: 'left',
}))
const StyledQuantity = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.24px',
  color: '#333333',
  textAlign: 'left',
}))
const StyledProtectionPlanVerbiage = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '13.92px',
  color: '#333333',
}))
const StyledPriceAtom = styled(PriceAtom)(() => ({
  color: '#333333',
  fontSize: '16px',
  fontWeight: 500,
}))
const StyledProductInfoGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'row',
  paddingRight: 15,
  [theme.breakpoints.up('md')]: {
    paddingRight: 0,
  },
}))
const StyledProductPriceGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: `${theme.spacing(0.5)} 0`,
}))

const ProductLineItem = ({
  image = 'https://via.placeholder.com/150',
  title = 'Product Title',
  price = '999999.99',
  protectionPlanSelected = false,
  protectionPlanPrice,
  quantity = 1,
  addOns = [
    {
      title: 'Bunkie Board',
      price: '99.99',
    },
  ],
  dtIdPrefix,
  savings = 0,
  couponApplied = false,
}) => (
  <Grid data-testid={`${dtIdPrefix}product`} container alignItems="flex-start">
    <Grid item xs={3}>
      <ItemImage>
        <Image
          data-testid={`${dtIdPrefix}product-image`}
          src={image || 'https://via.placeholder.com/150'}
          alt={title}
        />
      </ItemImage>
    </Grid>
    <Grid container direction="column" item xs={9}>
      <StyledProductInfoGrid item>
        <Grid container direction="column" justifyContent="flex-start" item>
          <Item>
            {couponApplied && <CouponTag />}
            <StyledTitle
              data-testid={`${dtIdPrefix}product-title`}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </Item>
          <ItemQuantity>
            <StyledQuantity data-testid={`${dtIdPrefix}product-quantity`}>{`Qt. ${quantity}`}</StyledQuantity>
          </ItemQuantity>
        </Grid>
        <StyledProductPriceGrid item data-testid={`${dtIdPrefix}product-price`}>
          <StyledPriceAtom suppressCentsIfZero price={price * quantity - savings} />
        </StyledProductPriceGrid>
      </StyledProductInfoGrid>
      {protectionPlanSelected && (
        <StyledProductInfoGrid item data-testid={`${dtIdPrefix}protection-plan`}>
          <Item>
            <StyledProtectionPlanVerbiage data-testid={`${dtIdPrefix}protection-plan-text`}>
              Furniture Protection Plan Added
            </StyledProtectionPlanVerbiage>
          </Item>
          <StyledProductPriceGrid item data-testid={`${dtIdPrefix}protection-plan-price`}>
            <StyledPriceAtom suppressCentsIfZero price={protectionPlanPrice * quantity} />
          </StyledProductPriceGrid>
        </StyledProductInfoGrid>
      )}
      {addOns &&
        addOns.map(addOn => (
          <StyledProductInfoGrid data-testid={`${dtIdPrefix}add-on-item`} key={addOn.title} item>
            <Grid item>
              <Item>
                <StyledProtectionPlanVerbiage data-testid={`${dtIdPrefix}addon-title`}>
                  {addOn.title}
                </StyledProtectionPlanVerbiage>
              </Item>
            </Grid>
            <StyledProductPriceGrid item data-testid={`${dtIdPrefix}addon-price`}>
              <StyledPriceAtom suppressCentsIfZero price={productPrice(addOn) * addOn.quantity * quantity} />
            </StyledProductPriceGrid>
          </StyledProductInfoGrid>
        ))}
    </Grid>
  </Grid>
)

export default ProductLineItem

ProductLineItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  protectionPlanSelected: PropTypes.bool,
  protectionPlanPrice: PropTypes.number,
  quantity: PropTypes.number,
  addOns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  dtIdPrefix: PropTypes.string,
  savings: PropTypes.number,
  couponApplied: PropTypes.bool,
}
