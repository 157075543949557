import React from 'react'
import { Typography } from '@mui/material'
import RTGLink from '@shared/link'
import PopoverTip from '@components/shared/popoverTip'

export const AddressTip = () => (
  <PopoverTip iconAriaLabel="delivery options tip" iconSize="small">
    <Typography variant="body2" sx={{ p: 2.5, fontSize: '16px', fontWeight: 400, lineHeight: '21px', width: 300 }}>
      Please provide shipping address to view available delivery options.
    </Typography>
  </PopoverTip>
)

export const PhoneDisclaimer = () => (
  <PopoverTip iconAriaLabel="phone disclaimer information" iconSize="small">
    <Typography variant="body2" sx={{ p: 3, fontSize: '14px', fontWeight: 400, lineHeight: '21px', width: 300 }}>
      You may be contacted at this number about your Rooms To Go order(s) or customer service by text message,
      prerecorded call, automated telephone dialing system or artificial voice. Message frequency varies. Message and
      data rates apply. Text STOP to opt out of these text messages. Text HELP for Customer Care. For more information
      see the&nbsp;
      <RTGLink
        style={{ textDecoration: 'underline', fontSize: 'inherit' }}
        data={{
          rel: 'noopener noreferrer',
          target: '_blank',
          url: 'https://legal.roomstogo.com/rtg-online#contract-h1qbyyz5u',
          category: 'shipping',
          action: 'click',
          label: 'label',
          textColor: '#0053A0',
        }}
      >
        Terms and Conditions of Sale, Limited Product Warranty, and Dispute Resolution/Arbitration Agreement.
      </RTGLink>
    </Typography>
  </PopoverTip>
)
