import React from 'react'
import { bool, number, object, string, array } from 'prop-types'
import { Stack, Typography, styled } from '@mui/material'
import ProfessionalDeliveryImage from '@assets/images/delivery-types/professional_delivery.svg'
import DoorwayDeliveryImage from '@assets/images/delivery-types/doorway_delivery.svg'
import SelfPickupImage from '@assets/images/delivery-types/self_pickup.svg'
import { PriceAtom } from '@rtgdev/design-system'
import { formatAvailableInDate } from '@helpers/date'
import DeliveryInstructions from '../delivery-instructions'
import DeliveryOptionDate from './delivery-option-date'

const DeliveryOptionIcon = styled(Stack)`
  flex: 0 0 60px;
  background-color: rgba(0, 83, 160, 0.04);
  border-radius: 10px;
  height: 60px;
  justify-content: center;
  img {
    max-width: 42px;
  }
`

const DeliveryOptionSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '19px',
  fontWeight: 400,
  color: theme.palette.primary.dark,
  '& span': { fontStyle: 'italic' },
}))

const AvailableDate = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: 'normal',
  fontWeight: 600,
  color: theme.palette.success.main,
  sup: {
    fontSize: '60%',
    verticalAlign: 'super',
    marginLeft: '-3px',
  },
}))

const DeliveryOptionDetailsList = styled('ul')(({ theme }) => ({
  listStyle: 'disc',
  listStylePosition: 'outside',
  paddingLeft: '1em',
  fontSize: '14px',
  lineHeight: '19px',
  color: theme.palette.secondary.dark,
  '& .pickup-address': {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  '& a': {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textDecoration: 'underline',
  },
  '& .highlighted': {
    color: theme.palette.error.main,
  },
  '& .bold': {
    fontWeight: 600,
    color: 'black',
  },
}))

const SubtitleExpressDelivery = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  color: '#333333B3',
  '.formatted-date': {
    textWrap: 'nowrap',
  },
  sup: {
    fontSize: '60%',
    verticalAlign: 'super',
    marginLeft: '-3px',
  },
}))

const renderAvailabiliyDate = date => {
  if (!date) return null
  const formattedDate = date.substring(0, date.length - 2)
  const supDate = date.substring(date.length - 2)
  return (
    <span className="formatted-date">
      {formattedDate} <sup>{supDate}</sup>
    </span>
  )
}

const PremiumDeliveryContent = ({
  additionalDirections,
  availableDate = '',
  dtId,
  expressFee,
  isChecked,
  isDefault,
  isExpress,
  isMobile,
  expressCalendar,
  ...splitOptionProps
}) => (
  <Stack p={2} spacing={1} data-testid={dtId}>
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <DeliveryOptionIcon direction="row">
        <img src={ProfessionalDeliveryImage} alt="Professional Delivery" />
      </DeliveryOptionIcon>
      <Stack spacing={isChecked ? 2 : 1}>
        <DeliveryOptionSubtitle variant="body2">
          Delivery & setup to any room, professional assembly, and removal of packaging
        </DeliveryOptionSubtitle>
        {!isChecked && !isDefault && availableDate && (
          <>
            <AvailableDate>Available {renderAvailabiliyDate(availableDate)}</AvailableDate>
            {expressCalendar?.[0] ? (
              <SubtitleExpressDelivery>
                {'Express Delivery as soon as '}
                {renderAvailabiliyDate(formatAvailableInDate(expressCalendar?.[0]))}
                {' for additional charge.'}
              </SubtitleExpressDelivery>
            ) : null}
          </>
        )}
        {isMobile && isChecked && !isDefault && availableDate && <DeliveryOptionDate isMobile {...splitOptionProps} />}
      </Stack>
    </Stack>

    {isChecked && (
      <DeliveryOptionDetailsList>
        {isMobile && expressFee > 0 && (
          <li>
            <Stack direction="row" spacing={0.5} alignItems="flex-end">
              <span>Express delivery charge added:</span>
              <PriceAtom disableSuperscript price={expressFee} />
            </Stack>
          </li>
        )}
        <li>4-hour delivery window.</li>
        <li>
          {`You will be notified of the estimated delivery window ${isExpress ? '' : '2 days '}prior to delivery.`}
        </li>
      </DeliveryOptionDetailsList>
    )}
    {isMobile && isChecked && <DeliveryInstructions additionalDirections={additionalDirections} isMobile />}
  </Stack>
)

PremiumDeliveryContent.propTypes = {
  additionalDirections: string,
  availableDate: string,
  dtId: string,
  expressFee: number,
  isChecked: bool,
  isDefault: bool,
  isExpress: bool,
  isMobile: bool,
  expressCalendar: array,
}

const DoorwayDeliveryContent = ({
  additionalDirections,
  availableDate = '',
  expressFee,
  dtId,
  isChecked,
  isDefault,
  isExpress,
  isMobile,
  expressCalendar,
  ...splitOptionProps
}) => (
  <Stack p={2} spacing={1} data-testid={dtId}>
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <DeliveryOptionIcon direction="row">
        <img src={DoorwayDeliveryImage} alt="Doorway Delivery" />
      </DeliveryOptionIcon>
      <Stack spacing={isChecked ? 2 : 1}>
        <DeliveryOptionSubtitle variant="body2">Placed inside the entryway or garage</DeliveryOptionSubtitle>
        {!isChecked && !isDefault && availableDate && (
          <>
            <AvailableDate>Available {renderAvailabiliyDate(availableDate)}</AvailableDate>
            {expressCalendar?.[0] ? (
              <SubtitleExpressDelivery>
                {'Express Delivery as soon as '}
                {renderAvailabiliyDate(formatAvailableInDate(expressCalendar?.[0]))}
                {' for additional charge.'}
              </SubtitleExpressDelivery>
            ) : null}
          </>
        )}
        {isMobile && isChecked && !isDefault && availableDate && <DeliveryOptionDate isMobile {...splitOptionProps} />}
      </Stack>
    </Stack>

    {isChecked && (
      <DeliveryOptionDetailsList isChecked={isChecked && !isMobile}>
        {isMobile && expressFee > 0 && (
          <li>
            <Stack direction="row" spacing={0.5} alignItems="flex-end">
              <span>Express delivery charge added:</span>
              <PriceAtom disableSuperscript price={expressFee} />
            </Stack>
          </li>
        )}
        <li>4-hour delivery window.</li>
        <li>{`You will be notified of the estimated delivery window ${
          isChecked && isExpress ? '' : '2 days '
        }prior to delivery.`}</li>
        <li className="bold">Signature required upon delivery.</li>
        <li className="highlighted">Does NOT include professional assembly or removal of packaging.</li>
      </DeliveryOptionDetailsList>
    )}
    {isMobile && isChecked && <DeliveryInstructions additionalDirections={additionalDirections} isMobile />}
  </Stack>
)

DoorwayDeliveryContent.propTypes = {
  additionalDirections: string,
  availableDate: string,
  dtId: string,
  expressFee: number,
  isChecked: bool,
  isDefault: bool,
  isExpress: bool,
  isMobile: bool,
  expressCalendar: array,
}

const SelfPickupContent = ({ availableDate = '', dtId, isChecked, isMobile, pickupLocation, ...splitOptionProps }) => (
  <Stack p={2} spacing={1} data-testid={dtId}>
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <DeliveryOptionIcon direction="row">
        <img src={SelfPickupImage} alt="Self pickup" />
      </DeliveryOptionIcon>
      <Stack spacing={isChecked ? 2 : 1}>
        <DeliveryOptionSubtitle variant="body2">
          Self pickup from <span>{pickupLocation.distCenterName}</span>
        </DeliveryOptionSubtitle>
        {!isChecked && availableDate && <AvailableDate>Available {renderAvailabiliyDate(availableDate)}</AvailableDate>}
        {isMobile && isChecked && availableDate && <DeliveryOptionDate isMobile {...splitOptionProps} />}
      </Stack>
    </Stack>

    {isChecked && (
      <DeliveryOptionDetailsList isChecked={isChecked}>
        <li>
          <span className="pickup-address">{pickupLocation.streetAddress}</span>
          <br />
          <a href={pickupLocation.phoneHref}>{pickupLocation.phoneDisplay}</a>
        </li>
        <li>Photo ID matching the billing name on the order required at time of pick up.</li>
        <li>You are responsible for transportation and assembly of merchandise.</li>
        <li>Furniture may only be picked up on the scheduled date.</li>
      </DeliveryOptionDetailsList>
    )}
  </Stack>
)

SelfPickupContent.propTypes = {
  availableDate: string,
  dtId: string,
  isChecked: bool,
  isMobile: bool,
  pickupLocation: object,
}

// eslint-disable-next-line import/prefer-default-export
export { PremiumDeliveryContent, SelfPickupContent, DoorwayDeliveryContent }
