import { styled, css, Grid } from '@mui/material'

export const StyledDeliveryCount = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: 16px;
    font-weight: 400;
  `,
)

export const StyledDeliveryDate = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: 23px;
    font-weight: 600;
    margin: 5px 0;
  `,
)

export const StyleDeliveryType = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0;
    text-transform: capitalize;
  `,
)

export const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 30px;
    ${theme.breakpoints.up('md')} {
      padding-left: 15px;
      padding-right: 50px;
    }
  `,
)
