import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setShippingInvalidFields } from '@redux/modules/checkout'
import { getCondensedShippingAddress } from '@helpers/checkout/shipping-section'
import { useTest } from '@hooks/useTest'
import { geoLocation } from '@helpers/geo-location'
import { useApolloClient } from '@apollo/client'
import ShippingCondensed from './shipping-condensed'
import ShippingSection from './shipping-section'

const ShippingSectionWrapper = () => {
  const { order, checkoutStep, shippingInvalidFields } = useSelector(state => state.checkout, shallowEqual)
  const apolloClient = useApolloClient()
  const dispatch = useDispatch()
  const { generateTestId } = useTest()
  const testIdPrefix = generateTestId('checkout', 'shipping')
  const getTestID = description => `${testIdPrefix}-${description}`
  const condensedAddress = getCondensedShippingAddress(order.shippingAddress)

  const updateInvalidFields = (update = []) => dispatch(setShippingInvalidFields(update))

  // For store cart orders, update location to Order shipping Zip code
  if (order && order.lineItems) {
    const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
    if (storeCartLineItems.length === order.lineItems.length)
      geoLocation(apolloClient, order?.shippingAddress?.zip, false)
  }

  if (checkoutStep !== 'shipping')
    return <ShippingCondensed contact={order.contact} shippingAddress={condensedAddress} />

  return (
    <ShippingSection
      getTestID={getTestID}
      invalidFields={shippingInvalidFields}
      order={order}
      updateInvalidFields={updateInvalidFields}
    />
  )
}

export default ShippingSectionWrapper
