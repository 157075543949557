import React from 'react'
import classNames from 'classnames'
import { string, bool, func, objectOf, any, arrayOf, node, oneOfType, object } from 'prop-types'

const getChecked = (textType, radioType, radioValue, info, field) => {
  if (textType) {
    return false
  }
  if (radioType) {
    if (info.financeCode && radioValue.code ? info.financeCode === radioValue.code || radioValue : radioValue) {
      return info[field]
    }
    return !info[field]
  }
  return info[field]
}

const getValue = (textType, radioType, radioValue, info, field) => {
  if (textType) {
    return info[field]
  }
  if (radioType) {
    return radioValue
  }
  return ''
}

const Input = ({
  afterComponent,
  className,
  disabled = false,
  field,
  info,
  id,
  invalidFields,
  inputAttributes = {},
  label,
  name,
  noLabel = false,
  onFocus,
  parentRef,
  placeholder,
  radioType,
  radioValue,
  required,
  setInfo,
  testID = '',
  textType,
  type,
}) => {
  const invalid = invalidFields && (invalidFields.includes(field) || invalidFields.includes(className))
  const classes = classNames(className, {
    invalid,
  })

  return (
    <>
      <input
        type={type}
        id={id || field}
        className={classes || className}
        placeholder={textType ? placeholder : ''}
        aria-required={required}
        aria-invalid={!!(required && classes)}
        aria-describedby={invalid ? `${field || className}-error` : null}
        value={getValue(textType, radioType, radioValue, info, field)}
        checked={getChecked(textType, radioType, radioValue, info, field)}
        name={radioType ? name : label}
        onChange={e => (!radioType ? setInfo(textType ? e.target.value : e.target.checked, field) : null)}
        onFocus={onFocus}
        onClick={() => (radioType ? setInfo(radioValue, field) : null)}
        ref={parentRef}
        disabled={disabled}
        data-testid={testID}
        {...inputAttributes}
      />
      {!noLabel && <label htmlFor={id || field}>{afterComponent && afterComponent}</label>}
    </>
  )
}

Input.propTypes = {
  afterComponent: node,
  className: string,
  disabled: bool,
  field: string,
  id: string,
  info: oneOfType([string, objectOf(any)]),
  invalidFields: arrayOf(string),
  inputAttributes: object,
  label: string,
  name: string,
  noLabel: bool,
  onFocus: func,
  parentRef: any,
  placeholder: string,
  radioType: bool,
  radioValue: oneOfType([string, objectOf(any)]),
  required: bool,
  setInfo: func,
  testID: string,
  textType: bool,
  type: string,
}

export default Input
