import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { arrayOf, bool, object, objectOf, string } from 'prop-types'
import { handleAffirm } from '@helpers/checkout/payment-section/affirm'
// import { getOrder } from '@helpers/checkout/global'
import PaymentSection from './payment-section'

const PaymentSectionWrapper = ({ cart, checkoutStep, checkoutStepsCompleted, order, paymentInvalidFields }) => {
  useEffect(() => {
    // const ord = getOrder()
    const ord = JSON.parse(sessionStorage.getItem('order'))
    if (ord) handleAffirm(ord)
  }, [])

  return (
    <PaymentSection
      cart={cart}
      checkoutStepsCompleted={checkoutStepsCompleted}
      invalidFields={paymentInvalidFields}
      isPaymentStep={checkoutStep === 'payment'}
      order={order}
    />
  )
}

PaymentSectionWrapper.propTypes = {
  cart: object,
  checkoutStep: string,
  checkoutStepsCompleted: objectOf(bool),
  order: object,
  paymentInvalidFields: arrayOf(string),
}

const mapStateToProps = ({ cart, checkout }) => ({
  cart: cart.cart,
  checkoutStep: checkout.checkoutStep,
  checkoutStepsCompleted: checkout.checkoutStepsCompleted,
  order: checkout.order,
  paymentInvalidFields: checkout.paymentInvalidFields,
})

export default connect(mapStateToProps)(PaymentSectionWrapper)
