import React from 'react'
import { objectOf, any, string, arrayOf, object, bool } from 'prop-types'
import { connect } from 'react-redux'
import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import { getDeliverySectionData } from '@helpers/checkout/delivery-section'
import { updateAddress } from '@services/checkout'
import { getAddressSpecificBody } from '@helpers/checkout/shipping-section'
import ErrorMessage from '@shared/error-message'
import DeliveryCondensed from './delivery-condensed'
import DeliverySectionForm from './delivery-section-form'
import DeliverySectionAutomatedMessage from './delivery-section-automated-message'
import { DeliverySectionWrapper } from './styles'
import { NON_RTG_DELIVERY_TYPES } from './constants'

const DeliverySection = ({ cart, deliveryCalendar, deliveryCondensed, invalidFields, isDeliveryStep, order }) => {
  const deliverySectionData = getDeliverySectionData(order, deliveryCalendar)
  const { defaultDeliveryInterval, fullDeliveryDate, nonRTGDeliveryItems, rtgDeliveryItems } = deliverySectionData

  if (order && order.defaultDelivery) {
    sentryLogger({
      configureScope: {
        type: setExtra,
        message: sentryMessages.deliveryCalendarFailure,
        level: levels.error,
        orderId: order.orderId,
      },
    })
  }

  // fullDeliveryDate is no longer used in DeliverySectionForm. Is checkDeliveryDate() still needed?
  const checkDeliveryDate = () => {
    if (order && order.deliveryCalendar) return order.deliveryCalendar[0]
    if (!order.deliveryCalendar)
      return updateAddress(getAddressSpecificBody(order)).catch(() => {
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.error,
            orderId: order.orderId,
            section: 'Checkout: Delivery Section',
          },
          captureMessage: {
            message: sentryMessages.updateAddressFailure,
            level: levels.error,
          },
        })
      })
    return null
  }

  const delDate = fullDeliveryDate || checkDeliveryDate()

  const nonRTGDeliveries = NON_RTG_DELIVERY_TYPES.map(type => ({ type, delivery: nonRTGDeliveryItems[type] })).filter(
    d => d.delivery.length > 0,
  )

  return (
    <>
      {(isDeliveryStep || deliveryCondensed) && order && (
        <DeliverySectionWrapper>
          {invalidFields.length > 0 && (
            <ErrorMessage
              invalidFields={invalidFields}
              customMessage={
                invalidFields.includes('payment incomplete')
                  ? 'You must complete delivery and payment before proceeding to review order.'
                  : 'There was an issue proceeding through checkout. Please refresh and try again.'
              }
            />
          )}
          {deliveryCondensed ? (
            <DeliveryCondensed
              cart={cart}
              defaultDeliveryInterval={defaultDeliveryInterval}
              nonRTGDeliveries={nonRTGDeliveries}
              order={order}
              rtgDeliveryItems={rtgDeliveryItems}
            />
          ) : (
            <DeliverySectionForm
              cart={cart}
              defaultDeliveryInterval={defaultDeliveryInterval}
              nonRTGDeliveries={nonRTGDeliveries}
              order={order}
              rtgDeliveryItems={rtgDeliveryItems}
            />
          )}
        </DeliverySectionWrapper>
      )}
      <DeliverySectionAutomatedMessage
        deliveryCondensed={deliveryCondensed}
        hasRtgDeliveryItems={rtgDeliveryItems?.length > 0 ?? false}
        order={order}
      />
    </>
  )
}

DeliverySection.propTypes = {
  cart: objectOf(any),
  deliveryCalendar: arrayOf(object),
  deliveryCondensed: bool,
  invalidFields: arrayOf(string),
  isDeliveryStep: bool,
  order: objectOf(any),
}

const mapStateToProps = ({ checkout, cart: { cart } }) => {
  const { checkoutStep, checkoutStepsCompleted, deliveryCalendar, deliveryInvalidFields } = checkout
  const isDeliveryStep = checkoutStep === 'delivery'
  const deliveryCondensed = !isDeliveryStep && checkoutStepsCompleted.delivery

  return {
    cart,
    deliveryCalendar,
    deliveryCondensed,
    invalidFields: deliveryInvalidFields,
    isDeliveryStep,
  }
}

export default connect(mapStateToProps, null)(DeliverySection)
