import React from 'react'
import { bool, func, string } from 'prop-types'
import { Dialog } from '@material-ui/core'

import BitPayLogo from '@assets/images/co-bitpay.svg'
import BitPayModal from './bitpay-modal'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeBitpay = ({ closeModals, isOpen, openModal, orderId }) => (
  <div>
    <PaymentTypeButton
      isFullLogo
      ariaId="payment-option-bitpay"
      gtmLabel="Bitpay Cryptocurrency"
      handleClick={() => openModal('bitpay')}
      label="Bitcoin & Ethereum"
      startIcon={<img src={BitPayLogo} width="175px" height="50px" alt="Bitpay Logo" />}
    />
    <Dialog
      onClose={closeModals}
      aria-labelledby="payment-option-bitpay"
      open={isOpen} // modalState.bitpay
      id="payment-modal-bitpay"
      tabIndex="0"
      className="rooms-to-go-credit-container"
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
    >
      <BitPayModal closeModal={closeModals} orderId={orderId} />
    </Dialog>
  </div>
)

PaymentTypeBitpay.propTypes = {
  closeModals: func,
  isOpen: bool,
  openModal: func,
  orderId: string,
}

export default PaymentTypeBitpay
