import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { onRemoveGiftCard } from '@helpers/checkout/payment-section/gift-cards'
import { Typography } from '@mui/material'
import CheckoutDialog from '../checkout-dialog'

import { PAYMENT_TYPES } from './payment-section'

const RemovePaymentModal = ({
  cardInfoRef,
  closeModals,
  order,
  removePayment,
  setShowRemoveConfirmation,
  showRemoveConfirmation,
}) => {
  const [paymentRemoveLoading, setPaymentRemoveLoading] = useState(false)

  // TODO - look at Gift Card removal to see if necessary to have separate function
  // TODO - Add GA event for removing payment?
  // TODO - should multi Gift Cards be handled like mutlti-tender CC - show applied GCs in the GC modal?

  const afterGiftCardRemovalCallback = loading => {
    setPaymentRemoveLoading(loading)
    if (!loading) handleCancel()
  }

  const handleCancel = () => {
    cardInfoRef.current = null
    setShowRemoveConfirmation(false)
  }

  const handleRemove = () => {
    closeModals()
    const paymentType = cardInfoRef.current?.paymentType
    if (paymentType === PAYMENT_TYPES.GIFT) {
      onRemoveGiftCard(order, cardInfoRef.current.paymentProperties?.cardNumber, afterGiftCardRemovalCallback)
    } else {
      const { appliedIndex } = cardInfoRef.current?.paymentProperties?.card ?? {}
      removePayment(order, setPaymentRemoveLoading, paymentType, handleCancel, appliedIndex)
    }
  }

  return (
    <CheckoutDialog
      actionButtonText="Yes"
      closeButtonText="No"
      contentSyles={{ padding: '12px 24px 0' }}
      dataTestIdPrefix="payment-removal-button"
      handleClose={handleCancel}
      handleContinue={handleRemove}
      isOpen={showRemoveConfirmation}
      loading={paymentRemoveLoading}
      showActionsDivider={false}
      title="Payment removal confirmation"
    >
      <Typography>Are you sure you want to remove this payment?</Typography>
    </CheckoutDialog>
  )
}

RemovePaymentModal.propTypes = {
  cardInfoRef: PropTypes.any,
  closeModals: PropTypes.func,
  order: PropTypes.object,
  removePayment: PropTypes.func,
  setShowRemoveConfirmation: PropTypes.func,
  showRemoveConfirmation: PropTypes.bool,
}

export default RemovePaymentModal
