import React from 'react'
import { bool, func, object } from 'prop-types'
import { Dialog } from '@material-ui/core'
import GiftCardLogo from '@assets/images/co-giftCard.svg'
import GiftCards from './gift-cards'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeGiftCard = ({ closeModals, isApplied, isOpen, openModal, order }) => (
  <div>
    <PaymentTypeButton
      hasBottomBorder
      isApplied={isApplied}
      isFullLogo
      ariaId="payment-option-rtg-gift-card"
      gtmLabel="Gift"
      handleClick={() => openModal('giftCard')}
      label="RTG Gift Card"
      startIcon={<img src={GiftCardLogo} width="175px" height="50px" alt="RTG Gift Card" />}
    />
    <Dialog
      onClose={closeModals}
      aria-labelledby="rtg-giftCard-modal"
      open={isOpen} // modalState.giftCard
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
    >
      <GiftCards closeModal={closeModals} order={order} />
    </Dialog>
  </div>
)

PaymentTypeGiftCard.propTypes = {
  closeModals: func,
  isApplied: bool,
  isOpen: bool,
  openModal: func,
  order: object,
}

export default PaymentTypeGiftCard
