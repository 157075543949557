import React, { useState } from 'react'
import { bool, node, string } from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import { colors } from '@constants/styles'
import CheckoutStepContent from './checkout-step-content'
import CheckoutStepHeader from './checkout-step-header'
import ShippingErrorModal from '../shipping-section/shipping-error-modal'
import loaderDark from '../../../../assets/images/loader-dark.svg'

const StyledSection = styled.div`
  background: ${colors.primaryInvert};
  text-align: right;
`

const StyledLoadingStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    height: 50px;
  }
`

const CheckoutStep = ({ children, loadingStep, previousSection, sectionNumber, sectionTitle, sectionType }) => {
  const { order, checkoutStep, checkoutStepsCompleted } = useSelector(state => state.checkout, shallowEqual)
  const [showModal, setShowModal] = useState(false)
  return (
    <StyledSection id={sectionType}>
      <ShippingErrorModal showModal={showModal} onCloseModal={() => setShowModal(false)} />
      <CheckoutStepHeader
        sectionTitle={sectionTitle}
        sectionType={sectionType}
        sectionNumber={sectionNumber}
        previousSection={previousSection}
        checkoutStep={checkoutStep}
        completed={checkoutStepsCompleted[sectionType] ?? false}
        setModal={setShowModal}
      />
      {!loadingStep ? (
        <CheckoutStepContent checkoutStep={checkoutStep} order={order} sectionType={sectionType}>
          {children}
        </CheckoutStepContent>
      ) : (
        <StyledLoadingStep>
          <img alt="checkout loading" src={loaderDark} />
        </StyledLoadingStep>
      )}
    </StyledSection>
  )
}

CheckoutStep.propTypes = {
  loadingStep: bool,
  previousSection: string,
  sectionNumber: string,
  sectionTitle: string,
  sectionType: string,
  children: node,
}

export default CheckoutStep
