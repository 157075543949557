import React from 'react'
import { objectOf, any, bool, func } from 'prop-types'
import { Collapse, styled } from '@mui/material'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { setFinancePlan } from './helpers'
import DownPayment from './down-payment'
import CheckoutInput from '../../checkout-input'
import { AfterComponentWrapper, AfterComponentLabel, LinkButton } from './styles'

const PromoDetailsCollapse = styled(Collapse)(() => ({
  maxHeight: '300px',
  overflow: 'scroll',
  paddingRight: '16px',
  paddingBotton: '16px',
}))

const SynchronyFinancePlan = ({ financePlanRef, isChecked, plan = {}, showPromoDetails, toggleDetails }) => (
  <CheckoutInput
    type="radio"
    field={plan.siteFriendlyLabel}
    id={plan.name}
    label={plan.siteFriendlyLabel}
    info={plan}
    setInfo={setFinancePlan}
    name="finance"
    radioValue={plan.financeCode}
    afterComponent={
      <AfterComponentWrapper>
        <AfterComponentLabel>{plan.siteFriendlyLabel}</AfterComponentLabel>
        <DownPayment info={plan} />
        <LinkButton type="button" className="link" onClick={() => toggleDetails(!showPromoDetails)}>
          Promotion Details {showPromoDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </LinkButton>
        {plan?.promoMessage && (
          <PromoDetailsCollapse in={showPromoDetails} timeout="auto">
            <div dangerouslySetInnerHTML={{ __html: plan.promoMessage.childMarkdownRemark.html }} />
          </PromoDetailsCollapse>
        )}
      </AfterComponentWrapper>
    }
    parentRef={financePlanRef}
    inputAttributes={{ checked: isChecked, style: { accentColor: '#0053A0' } }}
  />
)

SynchronyFinancePlan.propTypes = {
  financePlanRef: any,
  isChecked: bool,
  plan: objectOf(any),
  showPromoDetails: bool,
  toggleDetails: func,
}

export default SynchronyFinancePlan
