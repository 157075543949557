import React from 'react'
import { arrayOf, string, bool } from 'prop-types'
import useOneTimeChangeBool from '@hooks/useOneTimeChangeBool'

const errorMessages = {
  pickup: '*You must confirm the pickup date before continuing.',
  sevenYear: '*You must accept the lifestyle adjustable base plan terms and conditions before continuing.',
  serviceContract: '*You must accept the furniture protection plan terms and conditions before continuing.',
}

const AcceptanceErrorMessage = ({ accepted, field, invalidFields }) => {
  const isSubmissionTriedAndInvalid = invalidFields.length && !invalidFields.includes('order')
  const shownFirstTime = useOneTimeChangeBool(isSubmissionTriedAndInvalid)

  return (
    <>
      {((shownFirstTime && !accepted) || (!shownFirstTime && isSubmissionTriedAndInvalid)) && (
        <p className="invalid">{errorMessages[field]}</p>
      )}
    </>
  )
}

AcceptanceErrorMessage.propTypes = {
  accepted: bool,
  field: string,
  invalidFields: arrayOf(string),
}

export default AcceptanceErrorMessage
