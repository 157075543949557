import React from 'react'
import { arrayOf, string, bool } from 'prop-types'
import useOneTimeChangeBool from '@hooks/useOneTimeChangeBool'

const ReviewSectionInvalidFields = ({ invalidFields, acceptTermsChecked }) => {
  const acceptTermsAndConditions = `*You must accept the terms and conditions before continuing.`
  const invalidOrder = 'There was an issue placing your order.'

  const isSubmissionTriedAndInvalid = invalidFields && invalidFields.length > 0 && !invalidFields.includes('order')

  const shownFirstTime = useOneTimeChangeBool(isSubmissionTriedAndInvalid)

  return (
    <>
      {((shownFirstTime && !acceptTermsChecked) || (!shownFirstTime && isSubmissionTriedAndInvalid)) && (
        <p className="invalid">{acceptTermsAndConditions}</p>
      )}
      {invalidFields && invalidFields.includes('order') && <p className="invalid">{invalidOrder}</p>}
    </>
  )
}

ReviewSectionInvalidFields.propTypes = {
  invalidFields: arrayOf(string),
  acceptTermsChecked: bool,
}

export default ReviewSectionInvalidFields
