import React, { useEffect, useMemo, useState } from 'react'
import { any, array, func, number, object, oneOfType, string, bool } from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import { debounce } from '@mui/material/utils'
import { onAddressLookupChange, onAddressLookupSelect } from '@helpers/checkout/shipping-section'

const AddressAutocomplete = ({
  addressLookupValue,
  inputRef,
  invalidFields,
  label = 'Address Lookup',
  showManualAddress,
  testId,
  updateInvalidFields,
  width = 450,
  forceOpen = false,
  onFocus,
  onBlur,
}) => {
  const [value, setValue] = useState(addressLookupValue)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  // mark addressLookup field as invalid if any of the required address fields are invalid
  const isInvalid =
    invalidFields.includes('addressLookup') ||
    invalidFields.includes('address1') ||
    invalidFields.includes('address2') ||
    invalidFields.includes('city') ||
    invalidFields.includes('state') ||
    invalidFields.includes('zip')

  const fetchOptions = useMemo(
    () =>
      debounce((request, callback) => {
        onAddressLookupChange(request, callback)
      }, 400),
    [],
  )

  useEffect(() => {
    let active = true
    setLoading(true)
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      setLoading(false)
      return undefined
    }

    fetchOptions(inputValue, results => {
      if (active) {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }
        setLoading(false)
        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetchOptions])

  return (
    <Autocomplete
      id="address-autocomplete"
      open={inputRef?.current?.value?.length > 0 ? forceOpen : false}
      autoComplete
      filterOptions={x => x}
      filterSelectedOptions
      forcePopupIcon={options.length > 0}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      includeInputInList
      noOptionsText={loading ? 'Loading...' : 'No matches'}
      clearOnBlur={false}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        if (newValue) {
          if (invalidFields.some(fld => ['address1', 'address2', 'city', 'state', 'zip'].includes(fld))) {
            // a new address lookup has been selected so all addr fields can be cleared from invalidFields
            updateInvalidFields(
              invalidFields.filter(
                f => !['address1', 'address2', 'city', 'state', 'zip'].includes(f) && !f.endsWith('po-box'),
              ),
            )
          }
          onAddressLookupSelect(newValue.label, newValue.globalAddressId, showManualAddress)
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (isInvalid) updateInvalidFields(invalidFields.filter(f => f !== 'addressLookup'))
        setInputValue(newInputValue)
      }}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          autoComplete="street-address"
          InputProps={{ ...params.InputProps, style: { paddingTop: 0, paddingBottom: 0, paddingLeft: 0 } }}
          inputProps={{ ...params.inputProps, 'data-testid': testId, autoComplete: 'street-address' }} // eslint-disable-line
          inputRef={inputRef}
          error={isInvalid || value?.id === 'error'}
          FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400, textWrap: 'nowrap' } }}
          helperText={isInvalid || value?.id === 'error' ? `* Please enter a valid ${label}` : null}
          label={
            <>
              {label} <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
            </>
          }
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography variant="body2" color="text.secondary">
            {option.label}
          </Typography>
        </li>
      )}
      sx={{ width }}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

AddressAutocomplete.propTypes = {
  addressLookupValue: object,
  inputRef: any,
  invalidFields: array,
  label: string,
  showManualAddress: func,
  testId: string,
  updateInvalidFields: func,
  width: oneOfType([number, string]),
  forceOpen: bool,
  onFocus: func,
  onBlur: func,
}

export default AddressAutocomplete
