import React from 'react'
import { bool, number, string } from 'prop-types'
import { styled, Stack, TextField, Typography } from '@mui/material'
import { setDeliveryInfo } from '@helpers/checkout/delivery-section'
import { useTest } from '@hooks/useTest'

const Input = styled(TextField)`
  margin-top: 10px;
  letter-spacing: 0;
`

const InputHeader = styled(Stack)`
  justify-content: space-between;
  align-items: flex-end;
  p {
    font-size: 16px;
    font-weight: 400;
  }
`

const MobileLetterCount = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.lighter,
  fontSize: 12,
  fontWeight: 500,
  textAlign: 'end',
}))

const DeliveryInstructions = ({ additionalDirections, charLimit = 300, isMobile }) => {
  const { generateTestId } = useTest()
  return (
    <Stack>
      {!isMobile && (
        <InputHeader direction="row">
          <Typography pl={{ xs: 0, sm: '6px' }}>
            Anything we need to know to make sure delivery goes smoothly?
          </Typography>
          <p id="typeCountLimit">
            {additionalDirections.length}/{charLimit}
          </p>
        </InputHeader>
      )}
      <Input
        aria-describedby="typeCountLimit"
        id="howToDeliver"
        name="tell us how to deliver"
        onChange={e => setDeliveryInfo(e.target.value, 'additionalDirections')}
        label={isMobile ? 'Delivery instructions for our drivers' : 'Instructions for delivery'}
        type="text"
        data-testid="howToDeliver"
        value={additionalDirections}
        variant="outlined"
        inputProps={{
          maxLength: charLimit,
          'data-testid': generateTestId('checkout', 'How To Deliver Input'),
        }}
        multiline
        minRows={2}
      />
      {isMobile && (
        <MobileLetterCount id="typeCountLimit">
          {additionalDirections.length}/{charLimit}
        </MobileLetterCount>
      )}
    </Stack>
  )
}

DeliveryInstructions.propTypes = {
  additionalDirections: string,
  charLimit: number,
  isMobile: bool,
}

export default DeliveryInstructions
