import React, { useEffect, useState } from 'react'
import { any, func } from 'prop-types'
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material'
import loaderLight from '@assets/images/loader-light.svg'
import { onApplyGiftCard } from '@helpers/checkout/payment-section/gift-cards'
import { generateErrorMessage } from '@helpers/errors'
import { DialogDivider, LoadingSpinner } from './styles'

const GiftCards = ({ closeModal, order }) => {
  const [loading, setLoading] = useState(false)
  const [inputNumber, setInputNumber] = useState('')
  const [inputPin, setInputPin] = useState('')
  const [invalidFields, setInvalidFields] = useState([])
  const [unableToAddMessage, setUnableToAddMessage] = useState('')

  useEffect(() => {
    if (unableToAddMessage) setUnableToAddMessage('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputNumber, inputPin])

  if (!order) return null

  const updateGiftCardNumber = value => {
    if (invalidFields.includes('giftCardNumber')) {
      setInvalidFields(current => current.filter(f => f !== 'giftCardNumber'))
    }

    return setInputNumber(value || '')
  }

  const updateGiftCardPin = value => {
    if (invalidFields.includes('giftCardPin')) {
      setInvalidFields(current => current.filter(f => f !== 'giftCardPin'))
    }

    return setInputPin(value || '')
  }

  const handleSubmit = () => {
    const newInvalid = []
    const cardNum = inputNumber?.replaceAll(' ', '') ?? ''
    const cardPin = inputPin?.replaceAll(' ', '') ?? ''
    if (!cardNum) {
      newInvalid.push('giftCardEmpty')
    } else if (cardNum.length !== 16) {
      newInvalid.push('giftCardNumber')
    }
    if (!cardPin || cardPin === '') {
      newInvalid.push('pinEmpty')
      // eslint-disable-next-line no-restricted-globals
    } else if (!cardPin.match(/^.{4,8}$/) || isNaN(cardPin)) {
      newInvalid.push('pinNumber')
    }
    if (newInvalid.length) return setInvalidFields(newInvalid)

    setInvalidFields([])
    return onApplyGiftCard({ cardNumber: cardNum, pin: cardPin }, order, setLoading, setUnableToAddMessage, closeModal)
  }

  return (
    <>
      <DialogTitle id="rtg-giftCard-modal" component="div" sx={{ p: '24px 24px 12px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
            Enter Gift Card
          </Typography>
          <Typography component="i" sx={{ color: '#E11F21', fontSize: 12, fontWeight: 400, fontStyle: 'italic' }}>
            * Required
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent style={{ paddingTop: '8px' }}>
        <Stack gap={2}>
          {unableToAddMessage && <Typography sx={{ color: '#E11F21' }}>*{unableToAddMessage}</Typography>}
          <TextField
            value={inputNumber}
            error={invalidFields?.some(field => field.includes('giftCard')) ?? false}
            FormHelperTextProps={{
              style: { fontSize: 12, fontWeight: 400 },
            }}
            fullWidth
            helperText={
              invalidFields?.some(field => field.includes('giftCard'))
                ? `* ${generateErrorMessage(invalidFields.find(field => field.includes('giftCard')))?.message ??
                    'Please enter a valid Gift Card number.'}`
                : null
            }
            id="gift-card-number"
            label={
              <>
                Gift Card Number <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => updateGiftCardNumber(e.target.value)}
          />

          <TextField
            value={inputPin}
            error={invalidFields?.some(field => field.includes('pin')) ?? false}
            FormHelperTextProps={{
              style: { fontSize: 12, fontWeight: 400 },
            }}
            fullWidth
            helperText={
              invalidFields?.some(field => field.includes('pin'))
                ? `* ${generateErrorMessage(invalidFields.find(field => field.includes('pin')))?.message ??
                    'Please enter a valid Gift Card PIN.'}`
                : null
            }
            id="gift-card-pin"
            label={
              <>
                Gift Card Pin <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => updateGiftCardPin(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogDivider />
      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant="outlined" onClick={!loading ? closeModal : null}>
          Cancel
        </Button>
        <Button
          sx={{ height: '40px' }}
          disabled={!!unableToAddMessage}
          fullWidth
          variant="contained"
          onClick={!loading ? handleSubmit : null}
        >
          {!loading && 'Submit'}
          {loading && <LoadingSpinner alt="Submitting rooms to go credit card" src={loaderLight} />}
        </Button>
      </DialogActions>
    </>
  )
}

GiftCards.propTypes = {
  closeModal: func,
  order: any,
}

export default GiftCards
