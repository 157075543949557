import React, { useEffect } from 'react'
import RTGLink from '@shared/link'
import { objectOf, any, bool } from 'prop-types'
import { setReviewInfo, getFormattedCurrentDate } from '@helpers/checkout/review-section'
import { getEscUrl } from '@helpers/esc-plans'

const renderWarrantyLinkText = provider => {
  if (provider === 'FORTEGRA') {
    return 'Fortegra Guard It 3 Year Furniture Service Contract Terms and Conditions (Includes Dispute Resolution/Arbitration Agreement and Class Action Waiver).'
  }
  if (provider === 'EXTEND') {
    return 'Extend Guard It 3 Year Furniture Protection Plan Terms and Conditions (Includes Dispute Resolution/Arbitration Agreement and Class Action Waiver).'
  }
  return 'Guard It Residential Furniture Protection Plan Terms and Conditions (Includes Dispute Resolution/Arbitration Agreement and Class Action Waiver).'
}

const ReviewSectionWarrantyInfo = ({ isAccepted, isRequired }) => {
  const showWarranty = isRequired.acceptServiceContractTerms !== undefined
  const contract = showWarranty && isRequired.acceptServiceContractTerms

  const termsHeader = contract === 'FORTEGRA' ? 'Furniture Service Contract' : 'Furniture Protection Plan'
  const agreeToTerms = 'By checking this box, I acknowledge that I have read and agree to the'
  const agreeToElectronicDisclosure =
    'I consent to delivery of my Service Contract, and any related documents in electronic form.'
  const formattedCurrentDate = getFormattedCurrentDate()

  useEffect(() => {
    // following line is necessary, because on relaod it should be false
    if (isAccepted) setReviewInfo(false, 'acceptServiceContractTerms')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showWarranty && (
        <div className="checkbox-container">
          <input
            id="furnitureProtectionPlan"
            type="checkbox"
            name="Furniture Terms and Conditions"
            checked={isAccepted}
            onChange={e => setReviewInfo(e.target.checked, 'acceptServiceContractTerms')}
          />

          <label className="text-container" htmlFor="furnitureProtectionPlan">
            <span className="terms-header">{termsHeader}</span>
            <span className="accept-text">{agreeToTerms}</span>

            <RTGLink
              className="accept-link"
              data={{
                target: '_blank',
                url: getEscUrl(contract),
                category: 'warranty info',
                action: 'click',
                label: 'warranty text',
              }}
            >
              {renderWarrantyLinkText(contract)}
              <span className="hide508">(opens in new window)</span>
            </RTGLink>
            <span className="accept-text">&nbsp; {agreeToElectronicDisclosure}</span>
          </label>
        </div>
      )}
    </>
  )
}

ReviewSectionWarrantyInfo.propTypes = {
  isAccepted: bool,
  isRequired: objectOf(any),
}

export default ReviewSectionWarrantyInfo
