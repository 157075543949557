import React from 'react'
import { useSelector } from 'react-redux'
import { array, bool, func, number } from 'prop-types'
import { styled, Stack, Typography } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt'
import { getDateFull } from '@helpers/date'
import CalendarPopup from '../delivery-section-calendar-popup'
import CalendarPopupSplit from '../delivery-section-calendar-popup-split'
import SplitDeliveryOptionPicker from '../split-delivery-option-picker'

const DeliverySubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 12,
  fontWeight: 600,
}))

const DeliveryDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: 16,
  fontWeight: 600,
}))

const DeliveryInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 12,
  fontWeight: 400,
}))

const ExpressInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 10,
  fontWeight: 400,
}))

const DeliveryOptionDate = ({
  deliveryCount,
  isMobile,
  isSplitDelivery,
  shipmentsData,
  showSplitDeliveryOptionPicker,
  toggleSplit,
}) => {
  const { order } = useSelector(state => state.checkout)
  const { defaultDelivery: isDefault, deliveryDate, isExpress, isPickup } = order

  return (
    <Stack>
      {showSplitDeliveryOptionPicker && (
        <SplitDeliveryOptionPicker
          isMobile={isMobile}
          isPickup={isPickup}
          isSplitDelivery={isSplitDelivery}
          toggleSplit={toggleSplit}
        />
      )}
      {isSplitDelivery ? (
        shipmentsData.map((shipment, index) => {
          const fragmentKey = `shipment_${index}`
          return (
            <Stack key={fragmentKey} mb={1.3}>
              <DeliverySubtitle>{isPickup ? 'Pickup Date' : 'Delivery Date'}</DeliverySubtitle>
              <Stack direction="row" alignItems="center" spacing={1}>
                <DeliveryInfo>
                  Delivery {index + 1} of {deliveryCount}
                </DeliveryInfo>
                {shipment.expressDelivery && (
                  <Stack direction="row" alignItems="center">
                    <DeliveryInfo>-</DeliveryInfo>
                    <BoltIcon htmlColor="#003566" sx={{ fontSize: '0.875rem' }} />
                    <ExpressInfo>EXPRESS DELIVERY</ExpressInfo>
                  </Stack>
                )}
              </Stack>

              <DeliveryDate>{getDateFull(shipment.deliveryDate, true)}</DeliveryDate>

              <Stack direction="row" mt="-4px">
                <CalendarPopupSplit
                  index={index}
                  initialDate={shipment.deliveryDate}
                  isMobile={isMobile}
                  order={order}
                  shipment={shipment}
                  shipmentsData={shipmentsData}
                  testId="select-delivery-date-button-split"
                  text={isPickup ? 'Change Pickup Date' : 'Change Delivery Date'}
                />
              </Stack>
            </Stack>
          )
        })
      ) : (
        <>
          <DeliverySubtitle>{isPickup ? 'Pickup Date' : 'Delivery Date'}</DeliverySubtitle>
          {isExpress && !isPickup && (
            <Stack direction="row" alignItems="center">
              <BoltIcon htmlColor="#003566" sx={{ fontSize: '0.875rem' }} />
              <ExpressInfo>EXPRESS DELIVERY</ExpressInfo>
            </Stack>
          )}
          <DeliveryDate>{getDateFull(deliveryDate, true)}</DeliveryDate>
          {!isDefault && (
            <Stack direction="row" mt="-4px" mb={1.25}>
              <CalendarPopup
                initialDate={deliveryDate}
                isMobile={isMobile}
                order={order}
                testId="select-delivery-date-button"
                text={isPickup ? 'Change Pickup Date' : 'Change Delivery Date'}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}

DeliveryOptionDate.propTypes = {
  deliveryCount: number,
  isMobile: bool,
  isSplitDelivery: bool,
  shipmentsData: array,
  showSplitDeliveryOptionPicker: bool,
  toggleSplit: func,
}

export default DeliveryOptionDate
