import React, { useEffect, useState } from 'react'
import { objectOf, any, arrayOf, string, bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import { hideCouponInvalidModal } from '@redux/modules/checkout'
import InfoModal from '@shared/info-modal'
import { removeLocalStorage } from '@helpers/storage'
import { getCartsLegalRequirements } from '@helpers/checkout/review-section'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import ReviewSectionWarrantyInfo from './review-section-warranty-info'
import ReviewSectionInvalidFields from './review-section-invalid-fields'
import ReviewSectionTermsConditions from './review-section-terms-conditions'
import ReviewSectionEscConditions from './review-section-esc-storecart-conditions'
import ReviewSectionPickupConfirmation from './review-section-pickup-confirmation'
import { ReviewSectionWrapper, ModalContentWrapper } from './styles'
import AcceptanceErrorMessage from './acceptance-error-message'

export const ReviewSection = ({ checkoutStep, dispatch, invalidFields = [], order, showCouponInvalidModal }) => {
  const [requiredTerms, setRequiredTerms] = useState({})

  const getRequiredTerms = async () => {
    const newTerms = await getCartsLegalRequirements(order)
    setRequiredTerms(newTerms)
  }

  useEffect(() => {
    if (order?.paymentInfo?.length > 0) {
      addToDataLayer_nextgen('payment-type-confirm', { payment_type: order?.selectedPaymentType })
    }

    if (checkoutStep === 'review') {
      getRequiredTerms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutStep, order?.paymentInfo])

  // useEffect(() => {
  //   if (checkoutStep !== 'shipping') {
  //     setCheckoutDeliveryStep()
  //   }
  // }, [checkoutStep])

  const onModalClose = () => {
    dispatch(hideCouponInvalidModal())
    // TODO: navigation need to add code to track ga data

    navigate('/cart')
    removeLocalStorage('coupon')
    removeLocalStorage('couponType')
  }

  if (checkoutStep !== 'review') return null

  const isMissingTerms = invalidFields.includes('accept')
  const { reviewInfo } = order
  const { acceptTerms, acceptPickupTerms, acceptSevenYearTerms, acceptServiceContractTerms } = reviewInfo

  const missing = {
    pickup: isMissingTerms && requiredTerms.acceptPickupTerms && !acceptPickupTerms,
    sevenYear: isMissingTerms && requiredTerms.acceptSevenYearTerms && !acceptSevenYearTerms,
    serviceContract: isMissingTerms && requiredTerms.acceptServiceContractTerms && !acceptServiceContractTerms,
  }

  return (
    <>
      <ReviewSectionWrapper>
        <p className="review-text">Please review your order above and make any changes you need.</p>

        {/* RTG Terms - always required */}
        <ReviewSectionInvalidFields invalidFields={invalidFields} acceptTermsChecked={acceptTerms} />
        <ReviewSectionTermsConditions acceptTerms={acceptTerms} />

        {/* Pickup Terms - only required for Pickup delivery option */}
        {missing.pickup && (
          <AcceptanceErrorMessage accepted={acceptPickupTerms} field="pickup" invalidFields={invalidFields} />
        )}
        <ReviewSectionPickupConfirmation acceptPickupTerms={acceptPickupTerms} order={order} />

        {/* ...lifestyle adjustable base plan... */}
        {missing.sevenYear && (
          <AcceptanceErrorMessage accepted={acceptSevenYearTerms} field="sevenYear" invalidFields={invalidFields} />
        )}
        <ReviewSectionEscConditions isAccepted={acceptSevenYearTerms} isRequired={requiredTerms} />

        {/* ...furniture protection plan... */}
        {missing.serviceContract && (
          <AcceptanceErrorMessage
            accepted={acceptServiceContractTerms}
            field="serviceContract"
            invalidFields={invalidFields}
          />
        )}
        <ReviewSectionWarrantyInfo isAccepted={acceptServiceContractTerms} isRequired={requiredTerms} />
      </ReviewSectionWrapper>
      <InfoModal shouldShowModal={showCouponInvalidModal} closeModal={onModalClose}>
        <ModalContentWrapper>
          Sorry we could not validate the coupon at the moment. Your coupon may be already redeemed or expired.
        </ModalContentWrapper>
      </InfoModal>
    </>
  )
}

ReviewSection.propTypes = {
  checkoutStep: string,
  dispatch: func,
  invalidFields: arrayOf(string),
  order: objectOf(any),
  showCouponInvalidModal: bool,
}

const mapStateToProps = state => ({
  checkoutStep: state.checkout.checkoutStep,
  invalidFields: state.checkout.reviewInvalidFields,
  order: state.checkout.order,
  showCouponInvalidModal: state.checkout.showCouponInvalidModal,
})

export default connect(mapStateToProps)(ReviewSection)
