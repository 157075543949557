import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { useTest } from '@hooks/useTest'
import { styled } from '@mui/material/styles'

import PriceAtom from '../../../atoms/ProductPrice/PriceAtom'

const StyledTotalItemGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: 8,
  '&:last-of-type': {
    marginBottom: `${theme.spacing(1)} !important`,
  },
}))

const StyledSubheading = styled(Typography, { shouldForwardProp: prop => prop !== 'isDiscount' })(
  ({ isDiscount, theme }) => ({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    color: isDiscount ? theme.palette.error.border : theme.palette.secondary.dark,
  }),
)

const StyledMutedText = styled(Typography)(() => ({
  fontSize: '12px',
  lineHeight: '17px',
  color: '#757575',
}))

const TotalItems = ({ totalLineItems, missingDataMessage, dtIdPrefix }) => {
  const { generateTestId } = useTest()
  const isExpress = totalLineItems.some(item => item.subheading.endsWith('**'))

  return (
    <>
      {totalLineItems.map(({ subheading, price, isDiscount }) => (
        <StyledTotalItemGrid
          data-testid={generateTestId('total-items', `${subheading}-container`)}
          key={subheading}
          container
          justifyContent="space-between"
          item
        >
          <StyledSubheading data-testid={generateTestId('total-items', subheading)}>{subheading}</StyledSubheading>
          <StyledSubheading
            data-testid={generateTestId('total-items-element', `${subheading}-value`)}
            isDiscount={isDiscount}
          >
            {isDiscount && price !== '--' ? '-' : ''}
            {price === '--' ? (
              price
            ) : (
              <PriceAtom
                suppressCentsIfZero
                data-testid={generateTestId('total-items-value', `${subheading}-value`)}
                price={price}
              />
            )}
          </StyledSubheading>
        </StyledTotalItemGrid>
      ))}
      {missingDataMessage && (
        <StyledMutedText data-testid={`${dtIdPrefix}missing-data-message-open`}>{missingDataMessage}</StyledMutedText>
      )}
      {isExpress && (
        <StyledMutedText data-testid={`${dtIdPrefix}express-fee-message`}>
          **Express delivery fee applied
        </StyledMutedText>
      )}
    </>
  )
}

TotalItems.propTypes = {
  totalLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      isDiscount: PropTypes.bool,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subheading: PropTypes.string.isRequired,
    }),
  ),
  missingDataMessage: PropTypes.string,
  dtIdPrefix: PropTypes.string,
}

export default TotalItems
