const errorMessages = {
  acknowledge: {
    message: 'You must acknowledge the use of a Rooms To Go Finance Option before continuing',
    id: 'acknowledge-error',
  },
  address1: { message: 'Please enter a valid street address.', id: 'address1-error' },
  addressLookup: { message: 'Please enter a valid street address.', id: 'addressLookup-error' },
  buttonClick: { message: 'Invalid button click', id: 'buttonClick-error' },
  city: { message: 'Please enter a valid city.', id: 'city-error' },
  'delivery incomplete': { message: 'You must complete shipping and delivery before proceeding to payment.' },
  email: { message: 'Please enter a valid email address to proceed.', id: 'email-error' },
  financeEmpty: { message: 'Please enter account number.', id: 'finance-error-empty' },
  financeLength: { message: 'Please enter a valid account number.', id: 'finance-error-numbers' },
  financeAlphabetical: { message: 'Please enter only numerical values.', id: 'finance-error-alphabetical' },
  financePlan: { message: 'You must choose one of the finance options to proceed.', id: 'financePlan-error' },
  firstName: { message: 'Please enter a first name attributed to the shipping address.', id: 'firstName-error' },
  genesis: {
    message:
      'Your order cannot be processed at this time. Please contact Concora Credit customer service at 1-866-502-6439',
    id: 'finance-error',
  },
  'genesis-attempts': {
    message:
      'Your order cannot be processed at this time. Please contact Concora Credit customer service at 1-866-502-6439',
    id: 'finance-error',
  },
  'genesis-terms': {
    message:
      'You must check the box agreeing to finance your purchase using your Concora Credit Account in order to proceed.',
    id: 'terms-error',
  },
  giftCardEmpty: { message: 'Please enter a gift card number.', id: 'gift-card-error-empty' },
  giftCardNumber: { message: 'Invalid number of digits.', id: 'gift-card-error' },

  incomplete: { message: 'There are incomplete required fields. Please review your entry and try again.' },
  'ineligible state': {
    message:
      'We apologize, but some items or services are out of stock or unavailable for purchase in the specified zip code. Please review your cart and make changes as necessary.',
  },
  lastName: { message: 'Please enter a last name attributed to the shipping address.', id: 'lastName-error' },
  pinEmpty: { message: 'Please enter a pin number.', id: 'pin-error-empty' },
  pinNumber: { message: 'Please enter a valid Gift Card PIN.', id: 'pin-error' },
  phoneEmpty: { message: 'Please enter phone number.', id: 'phone-error-empty' },
  phoneLength: { message: 'Please enter your 10-digit phone number.', id: 'phone-error-digits' },
  phoneAlphabetical: { message: 'Please enter only numeric values.', id: 'phone-error-number' },
  phoneMatch: { message: 'Phone number does not match information on file.', id: 'phone-error-match' },
  'po box': { message: 'Rooms To Go cannot deliver to P.O. Boxes. Please enter a physical address.' },
  state: { message: 'Please enter a valid state.', id: 'state-error' },
  terms: { message: 'You must accept the terms and conditions before continuing.', id: `terms-error` },
  'unable to verify': { message: 'We were unable to verify your address. Please review your entry and try again.' },
  zip: { message: 'Please enter a valid zipcode.', id: 'zip-error' },
}

// eslint-disable-next-line import/prefer-default-export
export const generateErrorMessage = invalidField => errorMessages[invalidField] ?? { message: '' }

/**
 * Converts the Cybersource 3-digit Response Code into a displayable error message
 * explaining why a credit card payment was declined via the Checkout placeOrder call.
 * @param {string} responseCode
 * @returns {string}
 */
export const getPlaceOrderCreditCardErrorMsg = responseCode => {
  const tryAgain = 'Please select another payment type and resubmit your order.'
  switch (responseCode) {
    case '302': // Exceeds Credit Limit
    case '303': // Processor Declined
    case '508': // Processor Declined
    case '509': // Insufficient Funds Limit
    case '530': // Processor Declined
    case '596': // Stole/Lost Card
    case '606': // Unauthorized Card
    case '612': // Processor Declined
    case '614': // Unauthorized Card
    case '754': // Unauthorized Card
    case '806': // Processor Declined
      return `Credit card declined. ${tryAgain}`

    case '522':
      return `Credit card declined - Card Expired. ${tryAgain}` // Expired Card

    case '503': // CVN Not Match
    case '531': // Invalid CVN
    case '811': // CVN Not Match
      return `Credit card declined - Invalid CVN. ${tryAgain}`

    case '201': // Invalid Account
    case '607': // Processor Error
    case '613': // Issuer Unavailable
      return "We're sorry. We are unable to process your request right now. Please try again later."

    default:
      return "We're sorry. We are unable to process your request right now. Please try again later."
  }
}
