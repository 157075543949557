import React from 'react'
import { array, object } from 'prop-types'
import { Divider, Stack, styled, Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import PaymentSvg from '@components/shared/svgs/paymentSvgComp'
import { parseJwt } from '@helpers/checkout/payment-section/credit-card'
import { PAYMENT_CC_LABELS } from './payment-section/payment-section'

const Title = styled('h2')(() => ({
  color: '#07263B',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
}))

const FailedContainer = styled(Stack)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 500,
}))

const PlaceOrderMultiTenderError = ({ errorMessage, payments }) => (
  <Stack spacing={1}>
    <Title id="checkout-dialog-title">PAYMENT FAILED</Title>
    <Typography>One or more of your credit/debit cards were declined. Please re-try or apply another card.</Typography>
    <Divider />

    <Stack p={1} spacing={1}>
      {payments.map(({ card, token }) => {
        const { appliedIndex, cardName } = card
        if (appliedIndex !== errorMessage.declineIndex) return null

        const { maskedCardNum } = token ? parseJwt(token) : {}
        return (
          <Stack
            key={`cc_payment_error_${appliedIndex}`}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={0.75}>
              <PaymentSvg
                uniqueNameForId="multi-tender-payment-error"
                cards={[cardName]}
                cordY="0"
                vpHeight="50"
                width="30px"
                height="18px"
              />
              <Typography>{`${PAYMENT_CC_LABELS[cardName]} ${maskedCardNum}`}</Typography>
            </Stack>
            <FailedContainer direction="row" spacing={1}>
              <HighlightOffIcon />
              <span>Failed</span>
            </FailedContainer>
          </Stack>
        )
      })}
    </Stack>
  </Stack>
)

PlaceOrderMultiTenderError.propTypes = {
  errorMessage: object,
  payments: array,
}

export default PlaceOrderMultiTenderError
