import React, { useState } from 'react'
import { any, arrayOf, object, string } from 'prop-types'
import { styled, useMediaQuery } from '@mui/material'
import SynchronyFinancePlan from './synchrony-finance-plan'

const DetailsWrapper = styled('section')(() => ({
  margin: '0 0 10px',
  display: 'flex',
  '& > input': {
    marginLeft: 0,
  },
  '& > label': {
    marginLeft: 5,
    width: '100%',
  },
}))

const FinancePlan = ({ currentCode, financePlanRef, financePlan2Ref, plans = [] }) => {
  const [showDetails, setShowDetails] = useState([false, false])
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  if (plans.length === 0) return null

  const toggleDetails = (i, newValue) => {
    if (isDesktop) {
      setShowDetails(current => (i === 0 ? [newValue, current[1]] : [current[0], newValue]))
    } else {
      if (showDetails[0] || showDetails[1]) setShowDetails([false, false])
      setTimeout(() => setShowDetails(i === 0 ? [newValue, false] : [false, newValue]), 250)
    }
  }

  return (
    <section style={{ marginLeft: 8 }}>
      {plans?.map((plan, index) => {
        if (index > 1) return null
        const planRef = index === 0 ? financePlanRef : financePlan2Ref
        const isChecked = plan?.financeCode === currentCode
        return (
          <DetailsWrapper key={plan?.financeCode}>
            <SynchronyFinancePlan
              financePlanRef={planRef}
              isChecked={isChecked}
              plan={plan}
              showPromoDetails={showDetails[index]}
              toggleDetails={e => toggleDetails(index, e)}
            />
          </DetailsWrapper>
        )
      })}
    </section>
  )
}

FinancePlan.propTypes = {
  currentCode: string,
  financePlanRef: any,
  financePlan2Ref: any,
  plans: arrayOf(object).isRequired,
}

export default FinancePlan
