import React, { useState } from 'react'
import { format, parse } from 'date-fns'
import { fonts, colors, theme } from '@constants/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled as muiStyled } from '@mui/system'
import { makeStyles } from '@material-ui/core'
import BoltIcon from '@mui/icons-material/Bolt'
import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import PropTypes from 'prop-types'
import Price from '../../../../@rtg2022/components/atoms/Price/Price'

const StyledTextContainer = muiStyled(`div`)({
  margin: `1rem 20px 1rem 20px`,
})

const StyledExpressFee = muiStyled(Box)(() => ({
  display: `flex`,
  alignItems: `center`,
  marginBottom: '1rem',
  fontSize: '1rem',
  fontWeight: '400',
}))

const StyledPrice = muiStyled(Price)(() => ({
  marginLeft: '5px',
  fontWeight: 500,
}))

const StyledFooter = muiStyled(`h2`)({
  fontSize: fonts.txtSmall,
  fontWeight: theme.weight.regular,
  textAlign: `left`,
})
const StyledDeliveryText = muiStyled(`p`)({
  fontSize: fonts.txtMedium,
  fontWeight: `bold`,
  color: colors.lightBlue,
  textAlign: `left`,
})
const StyledSpan = muiStyled('span')({
  display: `flex`,
  flexWrap: `nowrap`,
  alignItems: `center`,
  fontSize: fonts.txtSmall,
  direction: 'rtl',
})
const StyledOrdinalSpan = muiStyled('span')({
  verticalAlign: `super`,
  fontSize: fonts.txtSmall,
})
const AvailableIcon = muiStyled(CircleOutlinedIcon)({
  color: colors.lightGrey,
  margin: `0 2px 0 5px`,
})
const SelectedIcon = muiStyled(CircleIcon)({
  color: colors.primary,
  margin: `0 2px 0 5px`,
})

const StyledTooltip = muiStyled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      backdropFilter: 'blur(5px)',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: fonts.txtSmall,
      border: `2px solid ${colors.lightGrey}`,
      borderRadius: '2px',
      padding: '10px',
      marginBottom: '7px !important',
    },
    [`& .${tooltipClasses.arrow}::before`]: {
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      border: `2px solid ${colors.lightGrey}`,
    },
  }),
)
/* make sure the tooltip is above the dialog */
const useStyles = makeStyles(() => ({
  tip: {
    zIndex: '10000000 !important',
  },
}))
const StyledUl = muiStyled(`ul`)({
  listStylePosition: 'outside',
  listStyleType: 'disc',
  marginLeft: '1em',
})

/**
 * takes a number and outputs the ordinal suffix of that number
 *
 * @param number
 * @returns {string}
 */
const getOrdinalNum = number => {
  let selector
  if (number <= 0) {
    selector = 4
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0
  } else {
    selector = number % 10
  }
  return ['th', 'st', 'nd', 'rd', ''][selector]
}

/**
 * creates the change date popup
 *
 * @param dt string
 * @param hasExpress boolean indicating if any express dates are available
 * @param isPickup boolean indicating if Self-Pickup delivery option was selected
 * @returns {JSX.Element}
 */
const PickerLegend = ({ dt, hasExpress, isPickup, expressFee }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const classes = useStyles()

  /* convert the date (2022/01/01) into a GMT date (to account for daylight savings) */
  const selectedDate = parse(dt, 'yyyy-MM-dd', new Date())

  /* format the date into a beginning and an end, so we can superscript the nd, st, th, etc */
  const dayOfWeek = format(selectedDate, 'eeee') // Saturday
  const monthOfYear = format(selectedDate, 'MMMM') // January
  const dayOfMonth = format(selectedDate, 'd') // 1
  const ordinalNum = getOrdinalNum(dayOfMonth) // st
  const calendarYear = format(selectedDate, 'yyyy') // 2022
  const formattedDateStart = `${dayOfWeek}, ${monthOfYear} ${dayOfMonth}` // Saturday, January 1
  const formattedDateEnd = ` ${calendarYear}` // 2022 (with a leading space)
  return (
    <div>
      <Grid container spacing={1}>
        <Grid container spacing={2}>
          <Grid item xs={2} />
          <Grid item xs={10} style={{ whiteSpace: `nowrap` }}>
            <StyledSpan>
              {/* today */}
              <AvailableIcon />
              <span style={{ paddingLeft: `4px` }}>Today</span>

              {/* selected */}
              <SelectedIcon />
              <span style={{ paddingLeft: `4px` }}>Selected</span>

              {/* express */}
              {hasExpress && (
                <>
                  <BoltIcon color="success" />
                  <StyledTooltip
                    title={
                      <>
                        <StyledUl>
                          <li>4 hour delivery window.</li>
                          <li>You will be notified of the delivery window prior to delivery.</li>
                          <li>Order must be completed before 2pm EST to receive selected date.</li>
                          <li>May increase delivery charge.</li>
                        </StyledUl>
                      </>
                    }
                    placement="top"
                    className={classes.tip}
                    open={showTooltip}
                    arrow
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                  >
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => setShowTooltip(!showTooltip)}
                      onKeyDown={() => setShowTooltip(!showTooltip)}
                    >
                      <span style={{ textDecoration: `underline dotted`, textUnderlineOffset: `2px` }}>Express</span>
                      <sup>*</sup>
                    </span>
                  </StyledTooltip>
                </>
              )}
            </StyledSpan>
          </Grid>
        </Grid>
      </Grid>

      <StyledTextContainer>
        {expressFee && expressFee > 0 ? (
          <StyledExpressFee>
            <BoltIcon color="success" fontSize="8px" />
            {'Upgrade to express delivery for '}
            <StyledPrice price={expressFee} />
          </StyledExpressFee>
        ) : null}
        <StyledFooter>{`Selected ${isPickup ? 'Pickup' : 'Delivery'} Date:`}</StyledFooter>
        <StyledDeliveryText>
          {formattedDateStart}
          <StyledOrdinalSpan>{ordinalNum}</StyledOrdinalSpan>
          {formattedDateEnd}
        </StyledDeliveryText>
      </StyledTextContainer>
    </div>
  )
}

PickerLegend.propTypes = {
  dt: PropTypes.string,
  hasExpress: PropTypes.bool,
  isPickup: PropTypes.bool,
  expressFee: PropTypes.number,
}

export default PickerLegend
