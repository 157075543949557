import React, { useEffect, useState } from 'react'
import { bool, object, string } from 'prop-types'
import { setDeclineModalClose } from '@helpers/checkout/global'
import CheckoutDialog from './checkout-dialog'
import PlaceOrderError from './place-order-error'
import PlaceOrderMultiTenderError from './place-order-multitender-error'

const DeclineModal = ({ modalOpen, multiTender, responseCode, type }) => {
  const [isloading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [modalOpen])

  if (multiTender) {
    if (document?.getElementById('payment')) {
      document?.getElementById('payment')?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleContinue = () => {
      setIsLoading(true)
      const { declineIndex } = multiTender.errorMessage
      const removeIndices = Array.isArray(declineIndex) ? [...declineIndex] : [declineIndex]

      setDeclineModalClose(type, 'CYBERV4', removeIndices)
    }

    return (
      <CheckoutDialog
        actionButtonText="Edit Payment"
        dataTestIdPrefix="order-error-button"
        dialogId="place-order-decline-modal"
        handleContinue={handleContinue}
        isOpen={modalOpen}
        loading={isloading}
        paperStyles={{ maxWidth: '400px' }}
        showActionsDivider={false}
      >
        <PlaceOrderMultiTenderError {...multiTender} />
      </CheckoutDialog>
    )
  }

  return (
    <CheckoutDialog
      actionButtonText="Close"
      contentSyles={{ padding: '4px 24px 0 24px', textAlign: 'center' }}
      dataTestIdPrefix="order-error-button"
      dialogId="place-order-decline-modal"
      handleContinue={() => {
        setIsLoading(true)
        setDeclineModalClose(type)
      }}
      isOpen={modalOpen}
      loading={isloading}
      paperStyles={{ maxWidth: '550px' }}
      showActionsDivider={false}
      title="Place Order Error"
    >
      <PlaceOrderError responseCode={responseCode} type={type} />
    </CheckoutDialog>
  )
}

DeclineModal.propTypes = {
  loading: bool,
  modalOpen: bool,
  multiTender: object,
  responseCode: string,
  type: string,
}

export default DeclineModal
