import React from 'react'
import { string } from 'prop-types'
import { Typography } from '@mui/material'
import { getPlaceOrderCreditCardErrorMsg } from '@helpers/errors'

const PlaceOrderError = ({ responseCode, type }) => (
  <>
    {type === 'Acima' && <Typography> We are unable to process your Acima Lease order at this time.</Typography>}
    {type === 'Delivery' && (
      <Typography>
        Your delivery date has expired. Please go back to reconfirm a delivery date and resubmit your order.
      </Typography>
    )}
    {type !== 'Acima' && type !== 'Delivery' && (
      <Typography>
        {responseCode
          ? getPlaceOrderCreditCardErrorMsg(responseCode)
          : `We were unable to authorize your ${type} payment. Please select another payment type and resubmit your order.`}
      </Typography>
    )}
    <Typography pt={2}>
      For help, please call <a href="tel:1-888-709-5380">1-888-709-5380</a> Option #1.
    </Typography>
  </>
)

PlaceOrderError.propTypes = {
  responseCode: string,
  type: string,
}

export default PlaceOrderError
