import React from 'react'
import { object } from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { getRemainingTotal } from '@helpers/checkout/global'
import { DownpaymentParagraph } from './styles'
import { isMonthsAboveThreshold } from './helpers'

const DownPayment = ({ info }) => {
  if (info === undefined) {
    return <DownpaymentParagraph>No down payment required.</DownpaymentParagraph>
  }
  const { downPaymentRequired, numberOfMonths, financeCode } = info || {}
  const orderAmounts = getRemainingTotal(true, null, true)
  // Check if finance plan is greater than 17 months; sometimes multi-month plans have numberOfMonths: 0, i.e. finance plan 36EQUALPMTS
  const requireAdditionalFee = numberOfMonths === 0 ? isMonthsAboveThreshold(financeCode) : numberOfMonths > 17

  const additionalFee = requireAdditionalFee
    ? `By selecting this payment method, Synchrony will charge an additional promo fee of 2% of the financed amount.`
    : `No promo fee applied.`

  return (
    <>
      {downPaymentRequired ? (
        <DownpaymentParagraph>
          <span>
            {`Small down payment required - ${
              orderAmounts ? currencyFormatUS(orderAmounts.tax + orderAmounts.deliveryCost) : currencyFormatUS(0)
            } (Shipping & Sales Tax). ${additionalFee}`}
          </span>
        </DownpaymentParagraph>
      ) : (
        <DownpaymentParagraph>No down payment required. {additionalFee} </DownpaymentParagraph>
      )}
    </>
  )
}

DownPayment.propTypes = {
  info: object,
}

export default DownPayment
