import React from 'react'
import styled from 'styled-components'
import { fonts } from '@constants/styles'
import { objectOf, any, bool, string } from 'prop-types'
import RTGLink from '@shared/link'

const Span = styled.span`
  font-weight: bold;
  font-size: 0.7rem;
`
const LinkText = styled.span`
  font-size: 0.7rem;
`

const AutomatedMessage = styled.p({
  textAlign: 'left',
  fontSize: `${fonts.txtXsmall} !important`,
  marginLeft: '1em',
  '@media screen and (min-width: 1024px)': {
    marginLeft: '0',
  },
})

const DoorwayDeliveryMessage = () => (
  <>
    <Span>Does not include professional assembly or removal of packaging. </Span>By selecting Doorway Delivery, you
    agree that you are solely responsible for assembly as set forth in the&nbsp;
    <RTGLink
      action="click"
      data={{
        url: 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
        title: 'Rooms To Go Terms of Use',
        action: 'click',
        target: '_blank',
      }}
    >
      <LinkText>
        Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
      </LinkText>
    </RTGLink>
    .
  </>
)

const automatedMessage = (isExpress, order) => {
  if (isExpress && order.doorwayDelivery) {
    return (
      <>
        *You will be notified of the delivery window prior to delivery.&nbsp;
        <DoorwayDeliveryMessage />
      </>
    )
  }
  if (isExpress) {
    return <>*You will be notified of the delivery window prior to delivery.</>
  }
  if (order.doorwayDelivery && !order.express) {
    return (
      <>
        *You will be notified of the estimated delivery window 2 days prior to delivery.&nbsp;
        <DoorwayDeliveryMessage />
      </>
    )
  }
  if (order.calendarType && order.calendarType === 'weekly') {
    return (
      <>
        *You will receive a call on the Thursday prior to the date that you selected. This call will identify the
        specific date and time that your merchandise will be delivered, within 12 days from the Monday you choose for
        your departure date.
      </>
    )
  }
  return <>*You will be notified of the estimated delivery window 2 days prior to delivery.</>
}

const DeliverySectionAutomatedMessage = ({ deliveryCondensed, hasRtgDeliveryItems, order }) => {
  const isExpress = order.isExpress || order?.splitDeliveryDates?.some(d => d.expressDelivery)
  let message
  if (isExpress) {
    message = '*You will be notified of the delivery window prior to delivery.'
  } else if (order.doorwayDelivery) {
    message = `*You will be notified of the estimated delivery window 2 days prior to delivery. Does not include professional assembly or removal of packaging/materials. Customer must be available to sign for delivery.`
  } else if (order.calendarType && order.calendarType === 'weekly') {
    message =
      '*You will receive a call on the Thursday prior to the date that you selected. This call will identify the specific date and time that your merchandise will be delivered, within 12 days from the Monday you choose for your departure date.'
  } else {
    message = '*You will be notified of the estimated delivery window 2 days prior to delivery.'
  }

  if (order && order.lineItems && deliveryCondensed && !order.isPickup && hasRtgDeliveryItems) {
    return (
      <>
        <AutomatedMessage className="mobile">{message}</AutomatedMessage>
        <AutomatedMessage className="desktop">{automatedMessage(isExpress, order)}</AutomatedMessage>
      </>
    )
  }

  return null
}

DeliverySectionAutomatedMessage.propTypes = {
  deliveryCondensed: bool,
  hasRtgDeliveryItems: bool,
  order: objectOf(any),
}

export default DeliverySectionAutomatedMessage
