import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import loaderLight from '@assets/images/loader-light.svg'
import { setOrderInfo } from '@helpers/checkout/global'
import { setCheckoutStep, setOrder } from '@redux/modules/checkout'
import { updatePayment } from '@services/checkout'
import { DialogDivider, LoadingSpinner } from './styles'

const BitPayModal = ({ closeModal, orderId }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleContinue = () => {
    setLoading(true)
    updatePayment({ orderId, paymentInfo: [{ paymentType: 'BIT', paymentProperties: {} }] })
      .then(finalOrder => {
        dispatch(setOrder(finalOrder))
      })
      .finally(() => {
        setOrderInfo('bitpay', 'selectedPaymentType')
        dispatch(setCheckoutStep('review'))
        setLoading(false)
      })
  }

  return (
    <>
      <DialogTitle id="payment-modal-title" component="div" sx={{ p: '24px 24px 12px 24px' }}>
        <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
          BitPay Payment
        </Typography>
      </DialogTitle>

      <DialogContent style={{ paddingTop: '8px' }}>
        <Typography sx={{ textAlign: 'left', fontWeight: 600, pb: 2 }}>
          Your order will be submitted to BitPay securely upon completing Step 4.
        </Typography>
        <Typography sx={{ textAlign: 'left' }}>
          Click CONTINUE to submit your order to BitPay or CANCEL to select another payment type.
        </Typography>
      </DialogContent>

      <DialogDivider />

      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant="outlined" onClick={!loading ? closeModal : null}>
          Cancel
        </Button>
        <Button sx={{ height: '40px' }} fullWidth variant="contained" onClick={!loading ? handleContinue : null}>
          {!loading && 'Continue'}
          {loading && <LoadingSpinner alt="Assigning BitPay as payment type" src={loaderLight} />}
        </Button>
      </DialogActions>
    </>
  )
}

BitPayModal.propTypes = {
  closeModal: func,
  orderId: string,
}

export default BitPayModal
