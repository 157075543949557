import PropTypes from 'prop-types'
import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import scriptLoader from 'react-async-script-loader'
import { getGiftCards } from '@helpers/checkout/payment-section/gift-cards'
import { visaCheckoutSetup } from '@helpers/checkout/payment-section/visa-checkout'
import VisaCheckoutLogo from '@assets/images/co-visaCheckout.svg'
import RTGLink from '@shared/link'

class VisaCheckoutButton extends React.Component {
  state = {
    showButton: false,
  }

  UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed, order }) {
    const { show } = this.state
    const { isScriptLoaded: isScriptLoadedProps } = this.props
    if (!show) {
      if (isScriptLoaded && !isScriptLoadedProps) {
        if (isScriptLoadSucceed) {
          this.visaCheckoutSetup(order)
        } else {
          console.error('Cannot load Visa Checkout script!')
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props
    if (prevProps.order !== order) {
      const prevGiftCards = getGiftCards(prevProps.order)
      const giftCards = getGiftCards(order)
      if (prevGiftCards !== giftCards) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showButton: false })
        this.visaCheckoutSetup(order)
      }
    }
  }

  visaCheckoutSetup = async order => {
    if (order) {
      setTimeout(() => {
        this.setState({ showButton: true }, () => visaCheckoutSetup(order), 500)
      })
    }
  }

  render() {
    const { showButton } = this.state
    return (
      <>
        {showButton && (
          <RTGLink
            data={{
              slug: '/checkout',
              category: 'checkout',
              action: 'payment-type-open',
              label: 'Visa',
            }}
          >
            <Button
              className="v-button"
              value="Affirm Checkout"
              variant="text"
              color="primary"
              fullWidth
              startIcon={<img src={VisaCheckoutLogo} width="175px" height="50px" alt="Visa Checkout" />}
            >
              <Box display="flex" width="100%" textAlign="left" style={{ textTransform: 'initial' }}>
                VISA Checkout
              </Box>
            </Button>
          </RTGLink>
        )}
      </>
    )
  }
}

VisaCheckoutButton.propTypes = {
  isScriptLoaded: PropTypes.any,
  order: PropTypes.any,
  isScriptLoadSucceed: PropTypes.bool,
}

export default scriptLoader(`${process.env.GATSBY_VISA_ASSETS_URL}/checkout-widget/resources/js/integration/v1/sdk.js`)(
  VisaCheckoutButton,
)
