import React from 'react'
import { bool, func } from 'prop-types'
import { styled, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'

const DeliveryTitle = styled(Typography, { shouldForwardProp: prop => prop !== 'isMobile' })(({ isMobile, theme }) => ({
  color: isMobile ? theme.palette.primary.dark : theme.palette.secondary.dark,
  fontSize: isMobile ? '12px' : '19px',
  fontWeight: isMobile ? 600 : 500,
}))

const OptionLabel = styled(Typography, { shouldForwardProp: prop => prop !== 'isMobile' })(({ isMobile, theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: isMobile ? '14px' : '16px',
  fontWeight: 400,
}))

const SplitDeliveryOptionPicker = ({ isPickup, isSplitDelivery, toggleSplit, isMobile }) => (
  <FormControl sx={{ mb: '20px', width: 'fit-content' }}>
    <FormLabel id="split-delivery-option-radio-buttons-group">
      <DeliveryTitle pb={{ xs: '2px', sm: '4px' }} pl={{ xs: 0, sm: '6px' }} isMobile={isMobile}>
        {isPickup ? 'Pickup Options:' : 'Delivery Options:'}
      </DeliveryTitle>
    </FormLabel>
    <RadioGroup
      aria-labelledby="split-delivery-option-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={isSplitDelivery ? 'split' : 'notsplit'}
      onChange={e => toggleSplit(e.target.value === 'split')}
      sx={{ ml: isMobile ? '4px' : '10px' }}
    >
      <FormControlLabel
        value="notsplit"
        sx={{ ml: 0, mr: 0 }}
        control={<Radio size="small" sx={{ padding: isMobile ? '4px' : '7px' }} />}
        label={<OptionLabel isMobile={isMobile}>{`${isPickup ? 'Pickup' : 'Deliver'} all sets together`}</OptionLabel>}
      />
      <FormControlLabel
        value="split"
        sx={{ ml: 0, mr: 0 }}
        control={<Radio size="small" sx={{ padding: isMobile ? '4px' : '7px' }} />}
        label={
          <OptionLabel isMobile={isMobile}>{`${isPickup ? 'Pickup' : 'Deliver'} as they are available`}</OptionLabel>
        }
      />
    </RadioGroup>
  </FormControl>
)

SplitDeliveryOptionPicker.propTypes = {
  isMobile: bool,
  isPickup: bool,
  isSplitDelivery: bool,
  toggleSplit: func,
}

export default SplitDeliveryOptionPicker
