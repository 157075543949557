import { store } from '@redux/store'
import { setOrder } from '@redux/modules/checkout'
import { updatePayment } from '@services/checkout'
import { sentryLogger, levels, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { hasIn } from 'lodash'
import { getOrder } from '../global'

/**
 * Returns an array of objects detailing each gift card payment that has been applied to the order
 * where each object includes properties: authorizedAmount, balance, giftCardNumber, and giftCardPin
 * @param {Object} [orderInput] Provide an order object or omit to have it fetched from redux
 * @param {Object[]} [orderInput.paymentInfo] Array of payment objects
 * @returns {Object[]}
 */
export const getGiftCards = orderInput => {
  const { paymentInfo = [] } = (orderInput || getOrder()) ?? {}
  return paymentInfo
    .filter(payment => payment.paymentType === 'GIFT')
    .map(({ authorizedAmount, paymentProperties }) => ({
      authorizedAmount,
      balance: paymentProperties.balance,
      giftCardNumber: paymentProperties.cardNumber,
      giftCardPin: paymentProperties.pin,
    }))
}

export const onApplyGiftCard = (paymentProperties, order, setLoading, setUnableToAddMessage, closeModal) => {
  // When adding a Gift Card, any existing non-Gift Card applied payments should
  // be removed since Gift Card payments are to be applied first
  const paymentInfo = order?.paymentInfo?.filter(p => p.paymentType === 'GIFT') ?? []
  const appliedAlready = paymentInfo.some(g => g.paymentProperties.cardNumber === paymentProperties.cardNumber)

  if (appliedAlready) return setUnableToAddMessage('Gift card has already been applied.')

  paymentInfo.push({ paymentType: 'GIFT', paymentProperties })
  setLoading(true)

  return updatePayment({ orderId: order.orderId, paymentInfo })
    .then(updatedOrder => {
      store.dispatch(setOrder(updatedOrder))
      closeModal()
    })
    .catch(err => {
      let errorMessage = 'Unable to add card'
      if (hasIn(err, 'response.request.responseText')) {
        const returnedErrorMessage = JSON.parse(err.response.request.responseText).error.message
        if (returnedErrorMessage === 'Can not use a gift card to pay for a gift card.') {
          errorMessage = returnedErrorMessage
        }
        if (returnedErrorMessage.match('Bonus Gift Card')) {
          errorMessage = returnedErrorMessage
        }
        if (returnedErrorMessage.includes('Bad EAN')) {
          errorMessage = 'Unable to add card. PIN # does not match Gift Card.'
        }
        if (returnedErrorMessage.includes('The EAN must have 8 digits')) {
          errorMessage = 'Unable to add card. PIN # must have 8 digits.'
        }
        if (returnedErrorMessage.includes('Inactive account')) {
          errorMessage = 'Unable to add card. Account # not found.'
        }
      }

      setUnableToAddMessage(errorMessage)

      sentryLogger({
        configureScope: {
          type: setExtra,
          level: levels.info,
          order: cleanOrderObject(order),
        },
        captureMessage: {
          type: 'text',
          message: 'Checkout - Gift Card update payment',
          level: levels.info,
        },
      })
    })
    .finally(() => setLoading(false))
}

export const onRemoveGiftCard = (order, giftCardNumber, setRemoving) => {
  setRemoving(true)
  if (order && order?.paymentInfo?.some(payment => payment.paymentType === 'GIFT')) {
    const paymentInfo = order.paymentInfo.filter(payment => payment?.paymentProperties?.cardNumber !== giftCardNumber)

    updatePayment({ orderId: order.orderId, paymentInfo })
      .then(updatedOrder => {
        store.dispatch(setOrder({ ...updatedOrder, cyberSourceSignature: null }))
      })
      .catch(() => {
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.error,
            order: cleanOrderObject(order),
            paymentType: 'GIFT',
          },
          captureMessage: {
            type: 'text',
            message: 'Checkout - Gift Cards remove card',
            level: levels.error,
          },
        })
      })
      .finally(() => setRemoving(false))
  }
}
