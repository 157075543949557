import styled from 'styled-components'
import { breakPoints, fonts, colors } from '@constants/styles'
import { styled as muiStyled } from '@mui/system'
import Popover from '@mui/material/Popover'
import Modal from 'react-modal'

export const Header = styled.h2`
  font-size: ${fonts.txtLarge};
  line-height: 1.375rem;
  margin: 1rem;
  padding: 0.5rem;
  font-weight: 700;
  border-bottom: 1px solid #e7e7e7;
  @media screen and (min-width: ${breakPoints.small}) {
    border-bottom: none;
  }
`

export const DatePickerWrapper = styled.div`
  width: 100%;
  padding: 2rem; // Todo mobile needs reduced padding
  &.express {
    .slider {
      .slick-prev,
      .slick-next {
        top: 38% !important;
        z-index: 0;
        @media screen and (min-width: ${breakPoints['large-min']}) {
          top: 13% !important;
        }
      }
    }
  }
  .slider {
    display: block !important;
    .slick-list {
      @media screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        width: 90% !important;
        margin: 0 auto;
      }
      @media screen and (max-width: ${breakPoints.small}) {
        width: 85% !important;
        margin: 0 auto;
      }
    }
    .slick-prev,
    .slick-next {
      top: 49% !important;
      @media screen and (min-width: ${breakPoints['large-min']}) {
        top: 16% !important;
      }
    }
    .slick-slider {
      margin: 0 -1em !important;
      @media screen and (min-width: ${breakPoints.medium}) {
        margin: 0 -2em !important;
        padding: 0 3rem !important;
      }
      @media screen and (min-width: ${breakPoints['large-min']}) {
        padding: 0 4rem !important;
      }
      .slick-prev,
      .slick-next {
        @media screen and (min-width: ${breakPoints['large-min']}) {
          transform: scale(2, 2);
        }
      }
    }
    .slick-slide {
      padding: 0 0.25em !important;
      margin-bottom: 5px;
    }
    .slick-list {
      overflow-y: visible;
      @media screen and (max-width: ${breakPoints.small}) {
        padding: 0.66rem 0 !important;
      }
      @media screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        padding: 0.5rem 0 !important;
      }
    }
    .slider {
      margin-top: 1.5em !important;
      width: 100%;
      padding: 2rem;
      @media screen and (max-width: ${breakPoints['large-max']}) {
        margin-top: 0 !important;
      }
    }
    .date {
      border: solid 1px ${colors.lightGrey};
      text-align: center;
      &.selected-date {
        background: ${colors.primary};
        p {
          color: ${colors.primaryInvert};
        }
        &:hover {
          background: ${colors.primary};
        }
      }
      &:hover {
        background: ${colors.lighterGrey};
        cursor: pointer;
      }
      .month {
        text-transform: uppercase;
        font-weight: 400;
        font-size: ${fonts.txtMediumUp};
        @media screen and (max-width: ${breakPoints['large-max']}) {
          font-size: ${fonts.txtMedium};
        }
        @media screen and (max-width: ${breakPoints.small}) {
          font-size: ${fonts.txtSmall};
        }
      }
      .day {
        font-weight: 700;
        font-size: 4.2em;
        line-height: 1;
        @media screen and (max-width: ${breakPoints['large-max']}) {
          font-size: 250%;
        }
        @media screen and (max-width: ${breakPoints.small}) {
          font-size: 220%;
        }
      }
      .weekday,
      .hours {
        text-transform: uppercase;
        font-weight: 400;
        font-size: ${fonts.txtMedium};
        margin-top: 0.3em;
        @media screen and (max-width: ${breakPoints['large-max']}) {
          font-size: ${fonts.txtXsmall};
        }
      }
      .hours {
        padding-top: 0.2rem;
        padding-bottom: 0.5rem;
      }
    }
    .restrictions {
      color: ${colors.darkGrey} !important;
      font-weight: 400;
      width: 100%;
      text-align: center;
      margin-top: 0.4rem;
      margin-bottom: -2.5rem;
      height: 2.1rem;
      font-size: ${fonts.txtSmall};
      @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        font-size: ${fonts.txtXsmall};
      }
      @media screen and (max-width: ${breakPoints.small}) {
        font-size: ${fonts.txtMini};
      }
    }
  }
`

export const ExpressIdentifier = styled.div`
  color: ${colors.darkerGrey};
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
  .asterisk {
    color: ${colors.red};
  }
  @media screen and (max-width: ${breakPoints.small}) {
    font-size: 14px;
  }
`

export const Description = styled.p`
  font-size: ${fonts.txtMedium};
  line-height: 1.375rem;
  text-align: left;
  margin-left: 1rem;
`

export const ContentWrapper = styled.div`
  text-align: center;
  background-color: ${colors.white};
  border: solid ${colors.lightGrey} 0.4rem;
  width: 340px;
  position: fixed;
  right: auto;
  bottom: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 1rem;
  @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    height: 90%;
    max-height: 90% !important;
  }
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    min-width: 10rem;
    //width: 33vw;
    max-height: 101%;
  }
  @media only screen and (max-width: ${breakPoints.small}) {
    width: 100%;
    min-height: 90%;
  }
`
export const DateWrapper = muiStyled('div')({
  textAlign: 'center',
  backgroundColor: `${colors.white}`,
  border: `solid ${colors.lightGrey} 0.2rem`,
  width: `340px`,
  paddingBottom: `1rem`,
  [`@media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)`]: {
    height: `90%`,
    maxHeight: `90% !important`,
  },
  [`@media only screen and (max-width: ${breakPoints['large-max']})`]: {
    minWidth: `10rem`,
    maxHeight: `101%`,
  },
  [`@media only screen and (max-width: ${breakPoints.small})`]: {
    width: `100%`,
    minHeight: `90%`,
  },
})

export const StyledPopover = muiStyled(Popover)({
  top: `0.5em`,
  zIndex: 1,
  color: `${colors.darkerGrey}`,
  '& .MuiPaper-root': {
    boxShadow: `none`,
    borderRadius: 0,
  },
})
