/**
 * keeps the scroll position after refresh
 * adapted from
 *  - https://mrcoles.com/blog/scroll-sneak-maintain-position-between-page-loads/
 *  - https://mrcoles.com/media/js/scroll-sneak.js
 * @param newPrefix
 * @param wait
 */
function ScrollSneak(prefix = null, wait = true) {
  const newPrefix = (typeof prefix === 'string' ? prefix : window.location.host).split('_').join('')
  let pre_name
  let tryScrollInterval

  this.tryToScrollTo = function tryToScrollTo(cssSelector = 'NON_EXISTING_SELECTOR', tries = 5, msInterval = 1000) {
    let counter = 0
    tryScrollInterval = setInterval(() => {
      if (counter >= tries) {
        clearInterval(tryScrollInterval)
      }

      const el = document.querySelector(cssSelector)
      if (el) {
        clearInterval(tryScrollInterval)
        this.scroll()
      }
      counter += 1
    }, msInterval)
  }

  // NOTE: All the code from here below comes from https://mrcoles.com/media/js/scroll-sneak.js
  // scroll function, if window.name matches, then scroll to that position and clean up window.name
  this.scroll = function scroll() {
    if (window.name.search(`^${newPrefix}_(\\d+)_(\\d+)_`) === 0) {
      const name = window.name.split('_')
      const [, second, third] = name
      window.scrollTo(Number(second), Number(third))
      window.name = name.slice(3).join('_')
    }
  }
  // if not wait, scroll immediately
  if (!wait) this.scroll()

  this.sneak = function sneak() {
    // prevent multiple clicks from getting stored on window.name
    if (typeof pre_name === 'undefined') pre_name = window.name

    // get the scroll positions
    let top = 0
    let left = 0
    if (typeof window.pageYOffset === 'number') {
      // netscape
      top = window.pageYOffset
      left = window.pageXOffset
    } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
      // dom
      top = document.body.scrollTop
      left = document.body.scrollLeft
    } else if (
      document.documentElement &&
      (document.documentElement.scrollLeft || document.documentElement.scrollTop)
    ) {
      // ie6
      top = document.documentElement.scrollTop
      left = document.documentElement.scrollLeft
    }
    // store the scroll
    if (top || left) window.name = `${prefix}_${left}_${top}_${pre_name}`
    return true
  }
}

export default ScrollSneak
